import { FC } from 'react';
import iconDialogo from '../../../../assets/iconos/iconDialogo.png'
import iconCelu from '../../../../assets/iconos/iconCelu.png'
import iconUbicacion from '../../../../assets/iconos/iconUbicacion.png'
import { ReactComponent as IsoLogo } from '../../../../assets/isoLogo1.svg'
import { ReactComponent as LogoLuca } from '../../../../assets/logoLuca.svg'
import { Outlet, useLocation } from 'react-router-dom';
import imagen from '../../../../assets/businessInsights/Recurso 2.svg'
import logo1 from '../../../../assets/imagenes/Recurso 1.svg'
import logo2 from '../../../../assets/imagenes/Recurso 2.svg'

export const AuthLayout: FC = () => {
  const location = useLocation()
  const isCompleteSignUp = location.pathname.includes('complete-signup')

  return (
    <div className='font-sans h-screen bg-slate-50 flex flex-col'>
      {isCompleteSignUp && (
        <header className='bg-primary h-[74px] w-full px-12 flex items-center shadow'>
          <div className='flex gap-2'>
            <IsoLogo className='text-slate-50 w-10 h-10' />
            <LogoLuca className='text-slate-50 w-[120px] h-auto' />
          </div>
        </header>
      )}
  
      <div className={`flex-1 ${isCompleteSignUp ? 'flex items-center justify-center' : ''}`}>
        {isCompleteSignUp ? (
          <div className='flex-none flex gap-4 items-center justify-center relative'>
            <Outlet />
          </div>
        ) : (
          <div className='flex w-full h-full'>
            <div className='flex-1 flex items-center justify-center'>
              <Outlet />
            </div>
            <div className='bg-primary text-white w-1/3 flex items-center justify-center relative'>
              <div className='text-center p-8'>
                <h1 className='px-8 text-5xl font-bold text-left text-white'>LUCAPP</h1>
                <p className='px-8 text-2xl mt-4 text-white text-left font-semibold'>Datos poderosos, decisiones inteligentes.</p>
                <p className='px-8 text-lg mt-2 text-left text-gray-300'>¿Revisaste hoy tus movimientos?</p>
                <img src={logo1} className='absolute top-32 right-20 w-24 h-24' />
                <img src={logo2} className='absolute bottom-32 left-16 w-14 h-14' />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}


export const ContactInfo: FC = () => (
  <div className='flex flex-col gap-9  mb-20'>
    <div className='flex gap-2 items-center'>
      <img className='w-12 h-12' src={iconDialogo} alt='iconUbicacion' />
      <div>
        <p className='font-bold uppercase'>hablemos</p>
        <p>contacto@tryluca.com</p>
      </div>
    </div>

    <div className='flex gap-2 items-center'>
      <img className='w-12 h-12' src={iconCelu} alt='iconUbicacion' />
      <div>
        <p className='font-bold uppercase'>llamanos</p>
        <p>+56 9 8662 5874</p>
      </div>
    </div>

    <div className='flex gap-2 items-center'>
      <img className='w-12 h-12' src={iconUbicacion} alt='iconUbicacion' />
      <div>
        <p className='font-bold uppercase'>visitanos</p>
        <p>
          Santa Magdalena 75, <br /> Providencia Santiago <br />
          Chile.
        </p>
      </div>
    </div>
  </div>
)

export const SecurityTSL: FC = () => (
  <div>
    <p>Ingresa los datos del SII para que
    Luca haga su magia..</p>
    <img src={imagen}/>
  </div>
)
