export const atributosColaborador=[
    {
        nombre: 'Reportes',
        
    },
    {
        nombre: 'Informes',
        
    },
    {
        nombre: 'Graficos',
        
    },
    {
        nombre: 'Contabilidad',
        
    },
    {
        nombre: 'Proyecciones',
        
    },
    
    ]