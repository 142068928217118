import { useState } from 'react'
import { Paper } from '../../../../ui/index'

const TableTraspaso = () => {
    const [order, setOrder] = useState({ field: null, direction: 'asc' })

    const cols = [
        { name: 'Año de Cierre', field: 'anioCierre'},
        { name: 'Cuenta Pérdida', field: 'cuentaPerdida' },
        { name: 'Cuenta Ganancia', field: 'cuentaGanancia' },
        { name: 'N° Comprobante', field: 'nComprobante' },
        { name: 'Glosa', field: 'glosa' },
        { name: 'Monto', field: 'monto' }
    ]

    const rows = [
        { anioCierre: '2023', cuentaPerdida: '', cuentaGanancia: 'Banco Bice', nComprobante: '10002', glosa: 'Comentarios aquí', monto: 10000000 },
        { anioCierre: '2023', cuentaPerdida: '', cuentaGanancia: 'Banco Santander', nComprobante: '10003', glosa: 'Comentarios aquí', monto: 1000000 },
        { anioCierre: '2023', cuentaPerdida: 'Tarjeta de crédito', cuentaGanancia: '', nComprobante: '10004', glosa: 'Comentarios aquí', monto: -6000000 },
        { anioCierre: '2023', cuentaPerdida: '', cuentaGanancia: 'Caja Principal', nComprobante: '10005', glosa: 'Comentarios aquí', monto: 1200000 }
    ]
  
    const handleSort = (field) => {
      const isAsc = order.field === field && order.direction === 'asc'
      setOrder({ field, direction: isAsc ? 'desc' : 'asc' })
    }

    const styles = {
        table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
        tr: 'border-b border-gray-200 hover:bg-gray-100/50',
        td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
        th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap',
        boldText: 'font-bold',
        text: 'text-xs text-gray-500'
      }
  
    const sortedRows = [...rows].sort((a, b) => {
        if (!order.field) return 0
        if (order.direction === 'asc') {
            return a[order.field] > b[order.field] ? 1 : -1
        }
        return a[order.field] < b[order.field] ? 1 : -1
    })

    return (
        <>
            <Paper>
                <div className=''>
                    <div className='overflow-x-auto'>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    {cols.map((col) => (
                                        <th
                                            key={col.field}
                                            className={styles.th}
                                            onClick={() => handleSort(col.field)}
                                        >
                                            {col.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedRows.map((row, index) => (
                                    <tr key={index} className={styles.tr}>
                                        <td className={styles.td}>{row.anioCierre}</td>
                                        <td className={styles.td}>{row.cuentaPerdida}</td>
                                        <td className={styles.td}>{row.cuentaGanancia}</td>
                                        <td className={styles.td}>{row.nComprobante}</td>
                                        <td className={styles.td}>{row.glosa}</td>
                                        <td className={styles.td}>${row.monto.toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Paper>
        </>
      )
}

export default TableTraspaso
