import { createContext } from 'react';
import { IGlobalContext } from '../models/Global-Context';

// Crear el contexto global con las nuevas propiedades
const GlobalContext = createContext<IGlobalContext>({
  ui: {
    loader: { visible: false, text: '' },
    setLoader: () => {}, // Función vacía inicial
    dialog: { title: '', body: '', btnText: '', open: false },
    setDialog: () => {}, // Función vacía inicial
    dialogForm: { title: '', body: '', btnTextClose: '', btnTextOpen: '', open: false },
    setDialogForm: () => {}, // Función vacía inicial
    blockedPeriods: [], // Inicialmente sin periodos bloqueados
    setBlockedPeriods: () => {}, // Función vacía inicial
    toggleBlockedPeriod: () => {}, // Función vacía inicial
    toggleAllPeriods: () => {}, // Función vacía inicial
    isPeriodBlocked: () => false, // Retorna falso como valor predeterminado
  },
  business: null,
  setBusiness: () => {}, // Función vacía inicial
  currentBusiness: undefined,
  setCurrentBusiness: () => {}, // Función vacía inicial
  currentBank: undefined,
  setCurrentBank: () => {}, // Función vacía inicial
});

export default GlobalContext;
