import { useEffect, useState } from 'react'
import InfoField from './StylesAjustes/EmpresaStyles'

const Empresa = ({ selectedBussines }) => {
  const [addresBussines, setAddressBussines] = useState({})

  useEffect(() => {
    // Verificamos si selectedBussines existe y tiene el atributo 'address' antes de llamar a transformAddress
    if (selectedBussines?.address) {
      transformAddress(selectedBussines)
    }
  }, [selectedBussines])

  function transformAddress(selectedBussines) {
    // Desestructuramos el atributo 'address' del objeto
    const { address } = selectedBussines

    // Dividimos el string 'address' por comas
    const addressParts = address.split(',')

    // Construimos el nuevo objeto con las partes específicas
    const transformedBusiness = {
      region: addressParts[0]?.trim(), // El primer elemento es la región
      comuna: addressParts[1]?.trim(), // El segundo elemento es la comuna
      direccion: addressParts.slice(2).join(',').trim() // Todo lo que viene después de la segunda coma es la dirección
    }

    setAddressBussines(transformedBusiness)
  }

  const isEmptyObject = obj => Object.keys(obj).length === 0

  return (
    <div className="p-6 max-w-4xl mx-auto rounded-3xl shadow-lg bg-white">
  {/* Fila 1 */}
  <div className="grid grid-cols-6 gap-6">
    <div className="col-span-4">
      <InfoField label="Nombre de fantasia" content={selectedBussines?.nombreFantasia} />
    </div>
    <div className="col-span-2">
      <InfoField label="Rut" content={selectedBussines?.rut} />
    </div>
  </div>

  {/* Fila 2 */}
  <div className="grid grid-cols-6 gap-6 mt-4">
    <div className="col-span-4">
      <InfoField label="Razón Social" content={selectedBussines?.name} />
    </div>
    <div className="col-span-2">
      <InfoField label="Email" content={selectedBussines?.email} />
    </div>
  </div>

  {/* Fila 3 */}
  <div className="grid grid-cols-6 gap-6 mt-4">
    <div className="col-span-3">
      <InfoField label="Calle" content={addresBussines?.direccion} />
    </div>
    <div className="col-span-1">
      <InfoField label="Número" content={selectedBussines?.id} />
    </div>
    <div className="col-span-2">
      <InfoField label="Teléfono" content={selectedBussines?.phoneNumber} />
    </div>
  </div>

  {/* Fila 4 - Of. Dpto., Región, Comuna y Ciudad en una misma línea */}
  <div className="grid grid-cols-12 gap-6 mt-4">
  <div className="col-span-2">
    <InfoField label="Of. Dpto." content={isEmptyObject(selectedBussines) ? '' : '311'} />
  </div>
  <div className="col-span-4">
    <InfoField label="Región" content={addresBussines?.region} />
  </div>
  <div className="col-span-3">
    <InfoField label="Comuna" content={addresBussines?.comuna} />
  </div>
  <div className="col-span-3">
    <InfoField label="Ciudad" content={isEmptyObject(selectedBussines) ? '' : 'SANTIAGO'} />
  </div>
</div>
</div>
)
}


export default Empresa
