//components
import { Title, Button, Text } from '../../../../modules/ui/index'
//asset
import iconAvion from '../../../../../assets/iconos/avionBlanco.png'
import iconLapiz from '../../../../../assets/iconos/iconoLapizEdit.png'

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export const Plan = () => {
  // const { currentBusiness } = useContext(GlobalContext)

  const EditCompanyInfoForm = () => (
    <div className='flex flex-col gap-2 pr-6'>
      <section className='flex flex-col gap-2'>
        <label htmlFor='type' className='font-bold'>
          Plan actual
        </label>
        <select
          id='type'
          className='w-2/4 appearance-none focus:outline-none focus:ring-0 block border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 bg-gray-100 font-medium'
        >
          <option>Plan Starter 2 UF mensuales</option>
          <option>Plan Starter 2 UF mensuales</option>
        </select>
      </section>
      <section className='flex gap-2'>
        <Button
          className='font-bold bg-accent2 text-white mt-6'
          title='CAMBIAR'
          imageSrc={iconAvion}
          imgStyle='w-8 ml-2'
        />
        <Button
          className='font-bold bg-primary text-white mt-6'
          title='REVISAR PLANES'
          imageSrc={iconLapiz}
          imgStyle='w-8 h-8 ml-2'
        />
      </section>

      <section className='grid grid-cols-3 mt-10 gap-4'>
        <div className='text-center'>
          <div className='bg-gray-400 text-white font-bold mb-10 px-4 py-2 text-1xl'>DEMO</div>
          <Text className='text-gray-400 font-bold'>Gratis</Text>
        </div>
        <div className='text-center'>
          <div className='bg-accent2 text-white font-bold mb-10 px-4 py-2 text-1xl'>STARTER</div>
          <Text className='text-accent2 font-bold'>2 UF</Text>
          <Text className='text-accent2'>mensuales</Text>
        </div>
        <div className='text-center'>
          <div className='bg-primary text-white font-bold mb-10 px-4 py-2 text-1xl'>GENIUS</div>
          <Text className='text-primary font-bold'>5 UF</Text>
          <Text className='text-primary'>mensuales</Text>
        </div>
      </section>
      <button className='w-3/12 rounded-full border border-gray-400 py-2  font-bold mt-5'>
        Eliminar cuenta
      </button>
    </div>
  )

  return (
    <div className='py-2'>
      <Title className='text-start mb-8 '>Plan</Title>

      <div>
        <EditCompanyInfoForm />
      </div>
    </div>
  )
}
