import { Form, Formik } from 'formik'
import { useLucaSignUp } from '../hooks/useLucaSignUp'
import { FieldWithErrorMessage } from '../../../ui/FieldWithErrorMsg'
import * as Yup from 'yup'
import { useSwiper } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const step2ValidationSchema = Yup.object({
  companyName: Yup.string().required('Ingresa el nombre de tu empresa')
})

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export function StepForm2() {
  const addAccountProfileValues = useLucaSignUp(state => state.setAccountProfile)
  const accountProfile = useLucaSignUp(state => state.accountProfile)
  const swiper = useSwiper()

  const step2Values = {
    companyName: accountProfile?.companyName ?? ''
  }

  /**
   *
   * @param {import('../part1/FormCreateUser').Step1Values} values
   * @param {import('formik').FormikHelpers<import('../part1/FormCreateUser').Step1Values>} actions
   */
  const handleNext = (values, actions) => {
    addAccountProfileValues(values)
    swiper.slideNext()
    actions.setSubmitting(true)
  }

  return (
    <Formik
      validationSchema={step2ValidationSchema}
      initialValues={step2Values}
      onSubmit={handleNext}
    >
      <Form className='px-1'>
        <h3 className='mb-8 text-3xl font-bold text-center'>Como se llama tu organización?</h3>
        <ul className='grid grid-cols-2 gap-x-8 gap-y-3'>
          <li className='col-span-2'>
            <FieldWithErrorMessage
              name='companyName'
              type='text'
              placeholder='Ingresa un nombre'
              label='Nombre de la Organización'
            />
          </li>
        </ul>

        <div className='flex flex-col gap-2 mt-8 w-2/3 mx-auto'>
          <div>
            <button type='submit' className='btn-primary rounded-full'>
              <span>Continuar</span>
              <FontAwesomeIcon size='lg' icon={faArrowRight} />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}
