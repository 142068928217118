import { Form, Formik, FormikHelpers } from "formik"
import { FC, useEffect } from "react"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { sendPasswordResetEmail } from "firebase/auth"
import { auth } from "../../../../../firebase"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { UserAuth } from "../../../../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { FieldWithErrorMessage } from "../../../ui/FieldWithErrorMsg"


type FormValues = { email: string }

const validationSchema = Yup.object<FormValues>({
  email: Yup.string().email('Ingresa un correo valido').required('Ingresa tu correo')
})


export const ResetPassword: FC = () => {
  const { user, isAuthenticated } = UserAuth()
  const navigate = useNavigate()

  const handleResetPassword =
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        actions.resetForm()
        await toast.promise(sendPasswordResetEmail(auth, values.email), {
          pending: 'Enviando correo... 🚀',
          success: {
            render() {
              return 'Revisa tu correo para reestablecer tu contraseña 👌'
            },
            onClose(props) {
              toast.success('Hemos enviado un correo de verificación')
            },
            className: 'bg-accent2 text-white',
          },
          error: {
            render(error) {
              const myError = error.data as AxiosError<{ message: string }>;
              if (myError.response?.status) {
                return myError.response?.data.message;
              }
              return 'No se pudo enviar el correo 🤯';
            },
            className: 'bg-accent1 text-white',
          }
        })
        actions.setSubmitting(false)
      }
      catch (error) {
        return null
      }
    }

  useEffect(() => {
    if (user && isAuthenticated) {
      navigate('/', { replace: true })
    }
  }, [user, isAuthenticated])

  return (
    <div className='w-[460px] shadow-md rounded-lg mb-12'>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleResetPassword}
      >
        <Form>
          <ul className='flex flex-col gap-4 p-12'>
            <li>
              <h1 className='text-3xl font-bold mb-4'>Reestablecer contraseña</h1>
            </li>
            <li>
              <FieldWithErrorMessage
                name="email"
                type="email"
                label="Ingresa tu correo"
                placeholder="Ingresa tu correo"
              />
            </li>
            <li>
              <button
                type='submit'
                className='btn-primary rounded-full'
              >
                <span>Enviar</span>
                <FontAwesomeIcon size='lg' icon={faPaperPlane} />
              </button>
            </li>
          </ul>
        </Form>
      </Formik>
    </div>
  )
}
