import { useState, useEffect } from "react";
import PlanDeCuentasModal from "../../../../modals/modalPlanDeCuentas/modalPlanDeCuentas";
import { PlanCuentasApi } from "../../../../../../api/planCuentas.api";
import SearchInput from "../../../../ui/SearchInput.js";
import CustomButton from "../../../../ui/CustomButton.js";
import FolderItem, { structureFolders } from "../PlanDeCuentas/Folders/FolderItem"; // Importar funciones desde FolderItem
import { PDFDownloadLink } from "@react-pdf/renderer"; // Librería para PDF
import { generateExcel, PlanDeCuentasPDF } from "../PlanDeCuentas/DocumentsPlanDeCuentas/ExportUtilsPlanDeCuentas.js"; // Importamos las utilidades
import translations from "../../../../ui/traslation/translationPLanDeCuentas.json"; // Archivo de traducción

const PlanDeCuentas = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [folders, setFolders] = useState([]); // Estado para los datos de carpetas
  const planCuentasApi = new PlanCuentasApi();

  useEffect(() => {
    const fetchPlanCuentas = async () => {
      try {
        const response = await planCuentasApi.getPlanCuentaById();
        const structuredData = structureFolders(response.data); // Estructura jerárquica
        setFolders(structuredData); // Guarda los datos en el estado
      } catch (error) {
        console.error(translations.errors.fetchPlanError, error); // Usar traducción para el mensaje de error
      }
    };

    fetchPlanCuentas(); // Llama a la función al montar el componente
  }, []);

  const pdfData = folders.flatMap((folder) =>
    folder.subfolders.length ? folder.subfolders : [folder]
  );

  return (
    <div>
      <div className="bg-white rounded-lg shadow-md p-6">
        {/* Barra de acciones */}
        <div className="flex justify-between items-center mb-4">
          <SearchInput
            placeholder={translations.actions.search} // Usar traducción para el placeholder
            classNameBorder="rounded-full"
          />
          <div className="flex space-x-2">
            {/* Botón para generar y descargar Excel */}
            <button
              onClick={() => generateExcel(folders)}
              className="w-10 h-10 text-green-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white"
            >
              {translations.actions.downloadExcel} {/* Usar traducción para el texto */}
            </button>
            {/* Botón para generar y descargar PDF */}
            <PDFDownloadLink
              document={<PlanDeCuentasPDF data={pdfData} />}
              fileName="plan_de_cuentas.pdf"
              className="w-10 h-10 text-red-700 font-bold cursor-pointer rounded-full shadow-full-xs bg-white flex items-center justify-center"
            >
              {translations.actions.downloadPDF} {/* Usar traducción para el texto */}
            </PDFDownloadLink>
          </div>
        </div>

        {/* Renderiza las carpetas */}
        {folders.length > 0 ? (
          folders.map((folder) => <FolderItem key={folder.id} folder={folder} />)
        ) : (
          <p>{translations.actions.noData} {/* Usar traducción para el mensaje de no datos */}</p>
        )}

        {/* Botón para abrir el modal */}
        <div className="flex justify-center">
          <CustomButton
            title={translations.actions.addPlan} // Usar traducción para el botón
            className="rounded-full px-8 py-2 font-semibold uppercase tracking-wide hover:shadow-md transition-all duration-300"
            color="accent2"
            onClick={() => setIsModalOpen(true)}
          />
        </div>

        {/* Modal de Plan de Cuentas */}
        {isModalOpen && (
          <PlanDeCuentasModal
            onClose={() => setIsModalOpen(false)}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </div>
  );
};

export default PlanDeCuentas;