import { useState } from 'react';
import { toast } from 'react-toastify';
import { Paper, Title } from '../../../../ui/index';
import { BusinessAPI } from '../../../../../../api/business.api';

//mui
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

const ListEmpresas = ({ bussinesAcount, setSelectedBussines }) => {
  const [selectedBusinessId, setSelectedBusinessId] = useState(null); // Estado para la empresa seleccionada
  const businessAPI = new BusinessAPI();

  const handleSelect = async (businessId) => {
    try {
      setSelectedBusinessId(businessId); // Actualiza la empresa seleccionada
      const response = await businessAPI.getBusinessById(businessId);
      setSelectedBussines(response.data); // Guarda los detalles completos en el estado
    } catch (error) {
      console.error("Error al obtener los detalles de la empresa:", error);
      toast.error("No se pudieron cargar los detalles de la empresa 🤯");
    }
  };

  return (
    <Paper className="shadow-full-xs rounded-3xl bg-secondary">
      <Title>Empresas</Title>
      <List className="bg-secondary">
        {bussinesAcount.map((unBussinesAcount) => (
          <ListItemButton
            key={unBussinesAcount.id}
            onClick={() => handleSelect(unBussinesAcount.id)}
            selected={selectedBusinessId === unBussinesAcount.id}
            className={`text-xs ${
              selectedBusinessId === unBussinesAcount.id
                ? 'text-[#1c3761] font-bold bg-gray-200' // Color y estilo para el seleccionado
                : 'text-[#8d9fb5] hover:bg-gray-100' // Color para los no seleccionados
            }`}
          >
            <ol>
              <li>{unBussinesAcount.name}</li>
              <li className="text-sm">{unBussinesAcount.rut}</li>
            </ol>
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};

export default ListEmpresas;