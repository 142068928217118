import { CompanyData } from './tabs/CompanyData'
import { AddNewCompany } from './tabs/AddNewCompany'
import { AddRepresentanteLegal } from './tabs/AddRepresentanteLegal'
import { Plan } from './tabs/Plan'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { UserAuth } from '../../../../context/AuthContext'
import { useSlide } from './tabs/useSlideHook'
// import { Users } from './tabs/users/Users'
// import { Roles } from './tabs/roles/Roles'

const slides = [
  <CompanyData key={1} />,
  <AddNewCompany key={2} />,
  <AddRepresentanteLegal key={3} />,
  <Plan key={4} />


]

/**
 * @typedef {Object} SettingsProps
 * @property {CallableFunction} onClose
 */

/**
 * A component that renders a Formik field with an error message.
 * @param {SettingsProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const Settings = ({ onClose }) => {
  const { slide, setSlide } = useSlide()
  const { logout } = UserAuth()

  const HeaderTitle = () => (
    <header className='mb-8 h-[60px] flex items-center justify-between border-b-2 border-slate-300'>
      <h2 className='text-xl font-bold'>Configuracion</h2>

      <button
        className='hover:scale-110 active:scale-100 transition-all'
        type='button'
        onClick={() => onClose()}
      >
        <FontAwesomeIcon size='lg' className='text-slate-500/85' icon={faX} />
      </button>
    </header>
  )

  const TabsList = () => (
    <ul className='flex flex-col gap-4 w-50'>
      <li>
        <button
          className={slide === 0 ? 'font-bold' : ''}
          type='button'
          onClick={() => setSlide(0)}
        >
          Datos de la empresa
        </button>
      </li>
      <li>
        <button
          className={slide === 1 ? 'font-bold' : ''}
          type='button'
          onClick={() => setSlide(1)}
        >
          Agregar empresa
        </button>
      </li>
      <li>
        <button
          className={slide === 2 ? 'font-bold' : ''}
          type='button'
          onClick={() => setSlide(2)}
        >
          Agregar representante legal
        </button>
      </li>
      {/* <li>
        <button
          className={slide === 3 ? 'font-bold' : ''}
          type='button'
          onClick={() => setSlide(3)}
        >
          Usuarios
        </button>
      </li> */}
      {/* <li>
        <button
          className={slide === 4 ? 'font-bold' : ''}
          type='button'
          onClick={() => setSlide(4)}
        >
          Roles
        </button>
      </li> */}
      <li>
        <button
          className={slide === 3 ? 'font-bold' : ''}
          type='button'
          onClick={() => setSlide(3)}
        >
          Plan
        </button>
      </li>
      <li>
        <button onClick={logout} type='button'>
          Cerrar sesión
        </button>
      </li>
    </ul>
  )

  const TabsContent = () => (
    <div className='relative overflow-hidden bg-white rounded-2xl mb-1 py-3 pl-6 pr-0 shadow-md flex-1'>
      <section
        style={{ scrollbarWidth: 'thin', scrollbarColor: '#c7c7c7 #f5f5f5' }}
        className='py-2 overflow-y-auto h-[460px] '
      >
        {slides[slide]}
      </section>
    </div>
  )

  return (
    <div className='ml-44 text-base p-8 pt-3 shadow-xl rounded-2xl bg-gray-100 w-[888px]'>
      <HeaderTitle />

      <section className='flex gap-7'>
        <TabsList />

        <TabsContent />
      </section>
    </div>
  )
}
