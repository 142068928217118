import { toast } from 'react-toastify'
import { BusinessAPI } from '../../../../../../api/business.api'
import { useState, useEffect } from 'react'

// components
// import { Paper } from '../../../../ui/index'
import ListEmpresas from './ListEmpresas'
import DataEmpresa from './DataEmpresa/Index'


const Index = () => {
  const businessAPI = new BusinessAPI()
  const [bussinesAcount, setBussinesAcount] = useState([])
  const [selectedBussines, setSelectedBussines] = useState({})

  const apiGetAllMaterial = async () => {
    try {
      const response = await businessAPI.getListBusiness()
      console.log(response.data)
      setBussinesAcount(response.data)
    } catch (error) {
      console.error('Error al guardar el objeto:', error)
    }
  }

  
  useEffect(() => {
    const getdataTable = async () => {
      await toast.promise(apiGetAllMaterial(), {
        pending: 'Cargando datos... 🚀',
        success: 'Datos cargados exitosamente... 👌',
        error: 'No se pudieron cargar los datos 🤯'
      })
    }

    getdataTable()
  }, [])

  return (
      
         <div className='grid grid-cols-3 grid-rows-1 gap-4'>
           <ListEmpresas bussinesAcount={bussinesAcount} setSelectedBussines={setSelectedBussines} />
           <div className='col-span-2'>
             <DataEmpresa selectedBussines={selectedBussines} />
           </div>
         </div>
 
    
  )
}

export default Index
