import PropTypes from 'prop-types';
import { colsSubTableComprobantes } from '../../../../data/headersTables'; // Importar las columnas
import translations from '../../../../ui/traslation/traslationMiContador.json'

const SubTable = ({ listaAuxiliar = [], fechaComprobante}) => {
  if (!listaAuxiliar || listaAuxiliar.length === 0) {
    return <div className="p-4 text-gray-500">{translations.tableComprobante.noDataSubTable}</div>;
  }

  const getFechaMas30Dias = () => {
    const baseFecha = new Date(fechaComprobante);
    baseFecha.setDate(baseFecha.getDate() + 30);
    return baseFecha.toLocaleDateString(); // Formatear la fecha
  };

  return (
    <div className="p-4 bg-gray-50">
      <table className="w-full text-xs text-left border-collapse">
        <thead>
          <tr className="bg-gray-100">
            {colsSubTableComprobantes.map((col, index) => (
              <th key={index} className="p-2 border">
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>

          {listaAuxiliar.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="p-2 border">{item.folio}</td>
              <td className="p-2 border">{item.razonSocial || 'N/A'}</td>
              <td className="p-2 border">{item.rut}</td>
              <td className="p-2 border">{item.tipoDocumento}</td>
              <td className="p-2 border">
                {item.razonSocial ?new Date(item.vencimiento).toLocaleDateString() : getFechaMas30Dias() }
              </td>
              <td className="p-2 border">{item.debe.toLocaleString()}</td>
              <td className="p-2 border">{item.haber.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SubTable.propTypes = {
  listaAuxiliar: PropTypes.arrayOf(
    PropTypes.shape({
      folio: PropTypes.string.isRequired,
      razonSocial: PropTypes.string,
      rut: PropTypes.string.isRequired,
      tipoDocumento: PropTypes.string.isRequired,
      vencimiento: PropTypes.string,
      debe: PropTypes.number.isRequired,
      haber: PropTypes.number.isRequired,
    })
  ).isRequired,
  fecha: PropTypes.string.isRequired,
};

export default SubTable;
