import GlobalContext from '../../../../../../context/global-context'
import { useContext } from 'react'
import { getWidgetToken } from '../bancos/modalConectFintoc/contectModalStep'

//assets
import imgBancosBienvenida from '../../../../../../assets/imagenes/imgBancosBienvenida.svg'
import iconCandado from '../../../../../../assets/iconos/iconCandado.png'

//components
import { Text, Button } from '../../../../ui'

const Wellcome = () => {
  const { currentBusiness } = useContext(GlobalContext)

  const handleGetWidgetToken = () => {
    getWidgetToken(currentBusiness.id)
  }

  return (
    <div>
      <div className='my-20 md:flex md:justify-between'>
        <img className='w-full md:w-3/12' src={imgBancosBienvenida} alt='Bienvenida'></img>
        <div className='flex flex-col justify-center items-center w-full'>
          <Text className='text-black text-4xl mb-6'>¡Ya casi está!</Text>
          <Text className='text-black text-2xl'>
            Vincula tus cuentas bancarias para poder disfrutar todas las
          </Text>
          <Text className='text-black text-2xl'>capacidades de Luca</Text>
          <Button
            className='font-bold bg-accent2 text-white mt-6'
            title='CONECTAR CUENTA'
            imageSrc={iconCandado}
            imgStyle='w-8'
            onClick={handleGetWidgetToken}
          />
        </div>
      </div>
      <hr className='w-[100%] bg-[#CCCCCC] h-1' />
      <div className='mt-16'>
        <Text className='text-black text-2xl mb-6 font-bold'>Preguntas Frecuentes</Text>
        <Text className='text-black text-lg italic font-bold'>
          ¿Por qué tengo que registrar mis credenciales bancarias en Luca?
        </Text>
        <Text className='text-black text-lg italic font-bold'>
          ¿Qué pasa si tengo más de una cuenta bancaria, cómo las registro?
        </Text>
      </div>
    </div>
  )
}

export default Wellcome
