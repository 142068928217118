import { Dialog, DialogContent } from '@mui/material'
import { useState, Fragment, useEffect, useContext } from 'react'
import GlobalContext from '../../../context/global-context'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import CustomButton from '../ui/CustomButton'
import { optionTipoComprobante, optionEstado } from '../data/dataComprobante'
import TablaNuevoComprobante from '../views/dashboard/MiContador/Comprobante/TablaNuevoComprobante'
import { OrganizationAPI } from '../../../api/organization.api'
import FilterControl from '../table/FilterControl'
import FilterDateControl from '../table/FilterDateControl'

const ModalEditMiContador = ({openModal, setOpenModal, comprobanteId, validation, createUserId, listaDataAuxiliar}) => {
  const [totalDebe, setTotalDebe] = useState(0)
  const [totalHaber, setTotalHaber] = useState(0)
  const [selectedDate, setSelectedDate] = useState('')
  const [tipoComprobante, setTipoComprobante] = useState('')
  const [estadoComprobante, setEstadoComprobante] = useState(0)
  const [libros, setLibros] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [glosa, setGlosa] = useState('')
  const organizationAPI = new OrganizationAPI()
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [data, setData] = useState()
  const [isBorrador, setIsBorrador] = useState(false)
  const [switchDate, setSwitchDate] = useState(true)
  const [razonSocial, setRazonSocial] = useState('')
  const [rut, setRut] = useState('')
  const [nombreComprobante, setNombreComprobante] = useState('')
  const [vencimiento, setVencimiento] = useState('')
  const [folio, setFolio] = useState('')
  const [listaAuxiliar, setListaAuxiliar] = useState([])

  const getDateToUse = () => {
    return switchDate && selectedDate ? selectedDate : new Date().toISOString().split('T')[0]
  }

  const handleClose = () => { setOpenModal(false) }
  
    const handleDateChange = (value) => {
      const selectedDate = new Date(value)
      const today = new Date()
      const minDate = new Date('2024-01-01')
    
      if (selectedDate >= minDate && selectedDate <= today) {
        setSelectedDate(value)
      } else {
        setSelectedDate('')
      }
    }
  
    const handleTextareaChange = (e) => {
      const value = e.target.value
      if (value.length > 50) {
        setErrorMessage('Máximo 50 caracteres permitidos')
      } else {
        setErrorMessage('')
      }
      setGlosa(value)
      setLibros((prevLibros) =>
        prevLibros.map((row) => ({
          ...row,
          glosa: row.glosa === glosa ? value : row.glosa,
        }))
      )
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          ui.setLoader({ visible: true, text: 'Cargando datos, por favor espera...' })
          let response
    
          if (validation >= 0) {
            setIsBorrador(false)
            response = await organizationAPI.getComprobanteEdit({
              businessId: currentBusiness.id,
              comprobanteId,
            })
          } else if (validation === -6) {
            setIsBorrador(true)
            response = await organizationAPI.getComprobanteBorrador({
              businessId: currentBusiness.id,
              comprobanteId,
            })
          }
    
          const data = response?.data || {}
          const librosProcesados = data?.libros?.map((libro) => ({
            debe: libro.debe || 0,
            haber: libro.haber || 0,
            glosa: libro.glosa || '',
            codigoCuenta: libro.cuentaContable?.codigoCuenta || '',
            nombreCuenta: libro.cuentaContable?.nombreCuenta || '',
            cuentaContableId: libro.cuentaContable?.id || null,
            tipoComprobanteId: libro.tipoComprobanteId || null,
            id: libro.id || null
          })) || []
    
          const tipoComprobanteId = librosProcesados[0]?.tipoComprobanteId || null
          const ids = librosProcesados.map((libro) => libro.id)
          const fechaProcesada = data?.fecha ? new Date(data.fecha) : null
          const vencimientoProcesado = data?.vencimiento
          ? new Date(data.vencimiento).toLocaleDateString('es-CL', { day: '2-digit', month: '2-digit', year: 'numeric' })
          : ''
  
        setData({
          ...data,
          libros: librosProcesados,
          tipoComprobanteId,
          fecha: fechaProcesada,
          vencimiento: vencimientoProcesado,
          ids
        })
          setSwitchDate(true)
        } catch (error) {
          console.error(error)
        } finally {
          ui.setLoader({ visible: false })
        }
      }
    
      if (comprobanteId) {
        fetchData()
      }
    }, [comprobanteId, validation])

    const formatDate = (isoString) => {
      if (!isoString) return ''
      const date = new Date(isoString)
      return date.toISOString().split('T')[0]
    }

    const formatDateToDDMMYYYY = (dateString) => {
      if (!dateString) return ''
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    }

    const mapTipoComprobante = (label) => {
      const option = optionTipoComprobante.find(opt => opt.label?.toLowerCase() === label?.toLowerCase())
      return option ? option.value : ''
    }

    useEffect(() => {
      if (data) {
        setSelectedDate(formatDate(data.fecha))
        setGlosa(data.glosa || '')
        setTipoComprobante(mapTipoComprobante(data.tipoComprobante) || data.tipoComprobanteId || '')
        setEstadoComprobante(data.estado)
        setRazonSocial(data.razonSocial)
        setRut(data.rut)
        setNombreComprobante(data.nombreComprobante || data.tipoComprobanteManual || '')
        setVencimiento(formatDateToDDMMYYYY(data.vencimiento))
        setFolio(data.folio)
      }
    }, [data])
    
    const handleSubmit = async () => {
      const ids = data?.ids || []
      const payload = {
        data: libros.map((row, index) => ({
          cuentaContableId: row.cuentaContableId ? Number(row.cuentaContableId) : '',
          id: ids[index],
          glosa: row.glosa,
        })),
        glosa: glosa || '',
      }
      try {
        ui.setLoader({ visible: true, text: 'Guardando datos...' })
        const response = await organizationAPI.patchComprobante(
          currentBusiness.id,
          payload
        )
    
        setData(response)
        handleClose()
      } catch (error) {
        console.error('Error al guardar:', error)
      } finally {
        ui.setLoader({ visible: false })
      }
    }

  const handleBorrador = async () => {
    if (parseInt(estadoComprobante) === 1) {
      if (!selectedDate || !tipoComprobante || !glosa || totalDebe === 0 || totalHaber === 0 || totalDebe !== totalHaber) {
        setErrorMessage('Todos los campos son obligatorios.')
        return
      }
      const librosActualizados = libros.map((row) => ({
        ...row,
        isDisabled: !row.codigoCuenta?.startsWith('3'), 
      }))
      setLibros(librosActualizados)
    }
    try {
      let vencimiento = data?.vencimiento || ''
      if (vencimiento) {
        const vencimientoDate = new Date(vencimiento.split('-').reverse().join('-'))
        
        if (isNaN(vencimientoDate)) {
          console.error('Fecha de vencimiento no válida:', vencimiento)
          setErrorMessage('Fecha de vencimiento no válida')
          return
        }
        vencimiento = vencimientoDate.toISOString().split('T')[0]
      }
      const payload = {
        fecha: getDateToUse(),
        tipo: parseInt(tipoComprobante),
        estado: parseInt(estadoComprobante),
        glosa: glosa,
        libros: libros.map(row => ({
          cuentaContableId: row.cuentaContableId ? row.cuentaContableId : (row.cuentaContableId || data?.libros?.[0]?.cuentaContableId),
          glosa: row.glosa || glosa,
          debe: parseFloat(row.debe) || 0,
          haber: parseFloat(row.haber) || 0,
          centroCosto: row.centroCostos || null,
          sucursal: row.sucursal || null,
        })),
        nombreComprobante: listaAuxiliar?.[0]?.nombreComprobante || nombreComprobante || data?.nombreComprobante || null,
        vencimiento: vencimiento || '',
        folio: listaAuxiliar[0]?.folio || folio || data?.folio,
        razonSocial: listaAuxiliar[0]?.razonSocial || razonSocial || data?.razonSocial,
        rut: listaAuxiliar[0]?.rut || rut || data?.rut,
        }
      ui.setLoader({ visible: true, text: 'Guardando Borrador...' })
        const response = await organizationAPI.updateBorrador(
            currentBusiness.id,
            comprobanteId,
            payload 
        )
          setData(response)
          handleClose()
      } catch (error) {
          console.error('Error al guardar:', error)
      } finally {
          ui.setLoader({ visible: false })
      }
    }
    
    const handleSave = () => {
      if (validation >= 0) {
        handleSubmit()
      } else if (validation === -6) {
        handleBorrador()
      }
    }

    const handleDelete = async () => {
      try {
        const payload = {
          fecha: getDateToUse(),
          tipo: parseInt(tipoComprobante),
          estado: -5,
          glosa: glosa,
          libros: libros.map(row => ({
            cuentaContableId: row.cuentaContableId || data?.libros?.[0]?.cuentaContableId,
            glosa: row.glosa || glosa,
            debe: parseFloat(row.debe) || 0,
            haber: parseFloat(row.haber) || 0,
            centroCosto: row.centroCostos || null,
            sucursal: row.sucursal || null,
          })),
          vencimiento: listaDataAuxiliar.vencimiento,
          folio: listaDataAuxiliar.folio,
          razonSocial: listaDataAuxiliar.razonSocial,
          nombreComprobante: listaDataAuxiliar.nombreComprobante,
          rut: listaDataAuxiliar.rut,
        }
        if (validation >= 0) {
          const libroPayload = {
            cuentaContableId: data?.libros?.[0]?.cuentaContableId,
            validation: -5,
            glosa: '',
            vencimiento: data?.vencimiento,
          }
          ui.setLoader({ visible: true, text: 'Eliminando...' })
          const response = await organizationAPI.updateLibroContable(currentBusiness.id, comprobanteId, libroPayload)
          setData(response)
        } else if (validation === -6) {
          ui.setLoader({ visible: true, text: 'Eliminando...' })
          const response = await organizationAPI.updateBorrador(currentBusiness.id, comprobanteId, payload)
          setData(response)
        }
    
        handleClose()
      } catch (error) {
        console.error('Error al procesar:', error)
      } finally {
        ui.setLoader({ visible: false })
      }
    }
    const isDescartarEnabled = (validation >= 0 && listaDataAuxiliar?.[0]?.haber > 0) || validation === -6
    return (
      <div>
        <Fragment>
          <Dialog open={openModal} maxWidth='lg' onClose={handleClose}>
            <DialogContent className='bg-gray-100'>
              <div className='flex justify-between items-center p-4 border-b'>
                <h2 className='text-lg font-semibold'>Editar Comprobante</h2>
                <button onClick={handleClose} className='text-gray-400 hover:text-gray-600'>
                  <CloseIcon />
                </button>
              </div>
              <div className='bg-gray-100 rounded-lg shadow-lg p-6 mt-6'>
                <div className='flex flex-wrap items-center gap-4'>
                  <FilterDateControl
                    label='Fecha'
                    value={selectedDate}
                    onChange={handleDateChange}
                    disabled={createUserId === null || validation >= 0}
                  />
                  <FilterControl
                    label='Tipo de Comprobante'
                    value={tipoComprobante || ''}
                    options={optionTipoComprobante || []}
                    onChange={(value) => setTipoComprobante(parseInt(value))}
                    disabled={createUserId === null || validation >= 0}
                  />
                  <FilterControl
                    label='Estado'
                    value={estadoComprobante}
                    options={optionEstado || []}
                    onChange={(value) => setEstadoComprobante(value)}
                    disabled={createUserId === null || validation >= 0}
                  />
                  <FilterControl label='Centro de Costo' value={''} options={[]} disabled />
                  <FilterControl label='Sucursal' value={''} options={[]} disabled />
                </div>
                <div className='mt-4'>
                  <label className='block text-sm font-medium text-gray-700'>Glosa</label>
                    <textarea
                      className='mt-1 w-full border-gray-300 rounded-lg shadow-sm'
                      rows='1'
                      value={glosa}
                      onChange={handleTextareaChange}
                      maxLength={50}
                      required={parseInt(estadoComprobante) === 1}
                      disabled={false}
                    ></textarea>
                  <p className='text-xs'>{glosa.length}/50</p>
                </div>
                <TablaNuevoComprobante
                  setTotalDebe={setTotalDebe}
                  setTotalHaber={setTotalHaber}
                  totalDebe={totalDebe}
                  totalHaber={totalHaber}
                  setLibros={setLibros}
                  glosaPrincipal={glosa}
                  isRequired={parseInt(estadoComprobante) === 1}
                  context="edit"
                  initialData={data?.libros || []}
                  isBorrador={isBorrador}
                  validation={validation}
                  razonSocial={data?.razonSocial || ''}
                  rut={data?.rut || ''}
                  nombreComprobante={nombreComprobante}
                  vencimiento={data?.vencimiento || vencimiento || null}
                  folio={data?.folio || ''}
                  setListaAuxiliar={setListaAuxiliar}
                  createUserId={createUserId}
                  listaDataAuxiliar={listaDataAuxiliar}
                />
                {errorMessage && <p className='text-red-500 text-sm mt-1'>{errorMessage}</p>}
                  <div className='sticky bottom-0 bg-white p-4 flex justify-between items-center'>
                      <CustomButton className='rounded-full' color='accent2' title='GUARDAR' onClick={handleSave}/>
                      <CustomButton className='rounded-full' color='primary' title='DESCARTAR' onClick={handleDelete} disabled={!isDescartarEnabled}/>
                  </div>
              </div>
            </DialogContent>
          </Dialog>
        </Fragment>
      </div>
    )
}

export default ModalEditMiContador