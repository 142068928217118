import { FC, useState } from "react";
import iconLapiz from '../../../../../../assets/iconos/iconLapiz.png'
import { useQueryGetUsersAll } from "../../../../../../queries/userQueries";
// import { atributosColaborador } from "../../../../../modules/data/dataAjustesColaborador";
// import { EditarUsuariosModal } from '../../../../../modules/modals/modalAtributosColaborador/ModalAtributosColaborador.js';  // Importamos el modal
import  ModalAtributosColaborador  from "../../../../../modules/modals/modalAtributosColaborador/ModalAtributosColaborador";
const thTable = [{ name: 'Nombre' }, {name: 'Correo Electronico'}, { name: 'Perfil de usuario' }, { name: 'Editar' }]


export const UsersList: FC = () => {
  const { data: userOrganizations = [] } = useQueryGetUsersAll();
  // const [selectedUser, setSelectedUsers] = useState(null);
  const[isModalOpen, setIsModalOpen] = useState(false);

  const openEditModal = (user) => {
    setIsModalOpen(true);
    // setSelectedUsers(user);
    console.log(user)
  };

  // const closeEditModal = (user) => setIsModalOpen(false);
  

  return (
    <div className="bg-white shadow-md p-6 rounded-lg">
    <table className="w-full text-left border-collapse">
      <thead>
        <tr>
          {thTable?.map((head, index) => (
            <th key={index} className="text-sm font-semibold text-gray-700 px-4 py-2 border-b">
              {head.name}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className='border-t-4 border-gray-200 mt-6 text-sm'>
        {userOrganizations?.map((unTdTable, index) => (
          <tr key={index} className="border-b">
            <td className='py-3 px-4'>{unTdTable.user?.firstname}</td>
            <td className='py-3 px-4'>{unTdTable.user?.email}</td>
            <td className='py-3 px-4'>{unTdTable.role?.name}</td>
            <td className='py-3 px-4'>
              <button onClick={() => openEditModal(unTdTable)}>
              <img src={iconLapiz} className="w-6 h-6" alt="Editar"/>
            </button>
            </td>
            
          </tr>
        ))}
      </tbody>
    </table>
    {isModalOpen && (
      <ModalAtributosColaborador
      isModalOpen = {isModalOpen}
      setIsModalOpen = {setIsModalOpen}
      />
    )}
 </div>)}