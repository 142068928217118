import { FC, useEffect, useRef, } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../../../../../context/AuthContext'
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react'
import { FormCreateUserAccount } from './FormCreateUser'
import { ResentValidationEmail } from './FormResendValidationEmail'

// step forms accountProfile sign up
const step1 = 0
const step2 = 1
const stepForms = [
  <FormCreateUserAccount key={step1} />,
  <ResentValidationEmail key={step2} />
]

export const SignUpPage: FC = () => {
  const navigate = useNavigate()
  const { user, isAuthenticated, isLoaded } = UserAuth()
  const swiperRef = useRef<SwiperRef>(null)

  useEffect(() => {
    if (user?.emailVerified && isLoaded) {
      navigate('/auth/complete-signup', { replace: true }) // go to email verified page (part 2)
    }
  }, [user, isAuthenticated, isLoaded])

  return (
    <>
      <div
        className='relative px-7 py-9 w-[570px] h-min[460px] bg-white rounded-lg shadow-md'
      >
        <Swiper
          ref={swiperRef}
          spaceBetween={30}
          allowTouchMove={false}

          initialSlide={user ? step2 : step1}
        >
          {stepForms.map((step, index) => (
            <SwiperSlide className='mb-1' key={index}>
              {step}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    </>
  )
}
