import { getFintoc } from '@fintoc/fintoc-js'
import { BankAPI } from '../../../../../../../api/bancos.api'
import { useBanks } from '../useBanks.hook'

const bankAPI = new BankAPI()
let widgetInstance = null

export const sendExchangeTokenToBackend = async (exchangeToken, businessId) => {
  try {
    const response = await bankAPI.sendExchangeTokenToBackend(exchangeToken, businessId)
    // const response = await Axios.get(
    //   `http://localhost:8080/v1/banks/link-token?exchangeToken=${exchangeToken}&businessId=${businessId}`
    // )

    console.log(response)
    // const accountId = response.data.accounts[0].id
    const accounts = response.data.accounts
    const linkToken = response.data.link_token
    console.log(accounts)
    console.log(linkToken)
    // Realizar llamadas en secuencia (una tras otra)
    for (const account of accounts) {
      const accountId = account.id
      const accountIdSql = account.accountIdSql

      console.log(`Procesando cuenta con ID: ${accountId} y accountIdSql: ${accountIdSql}`)

      try {
        const responseMovements = await bankAPI.getListMovements(
          accountId,
          linkToken,
          businessId,
          accountIdSql
        )
        console.log(responseMovements)
      } catch (error) {
        console.log(`Error al obtener movimientos para la cuenta ${accountId}:`, error)
      }
    }
    // const responseMovements = await bankAPI.getListMovements(accountId, linkToken, businessId)
    const getData = useBanks.getState().getData
    if (getData) {
      getData()
    }
    // Destruir el widget después de completar el proceso
    if (widgetInstance) {
      widgetInstance.destroy()
      widgetInstance = null
    }
  } catch (error) {
    console.log('Error al inicializar Fintoc:', error)
  }
}

export const main = async (widgetToken, businessId) => {
  const options = {
    publicKey: 'pk_live_aL4pwxPybnC2h2EDavExhmS3N5aHXuqD',
    widgetToken: widgetToken,
    product: 'accounts',
    country: 'CL',
    onSuccess: async linkIntent => {
      const exchangeToken = linkIntent.exchangeToken
      console.log(exchangeToken)
      await sendExchangeTokenToBackend(exchangeToken, businessId)
      // await Axios.get('http://localhost:8080/v1/banks/link-token',exchangeToken, businessId)
      // Después de completar el proceso, podemos destruir el widget
      if (widgetInstance) {
        widgetInstance.destroy()
        widgetInstance = null // Reiniciamos la variable
      }
    },
    onExit: () => {
      console.log('User has exited the widget.')
      // Destruir el widget cuando se cierra sin completar el proceso
      if (widgetInstance) {
        widgetInstance.destroy()
        widgetInstance = null // Reiniciamos la variable
      }
    }
  }
  try {
    const Fintoc = await getFintoc()
    const widget = Fintoc.create(options)
    widgetInstance = widget // Guardamos la instancia del widget
    //metodo open para abrir el widget
    widget.open()
  } catch (error) {
    console.error('Error al inicializar Fintoc:', error)
  }
}

export const getWidgetToken = async businessId => {
  const payload = {
    product: 'movements',
    country: 'cl',
    holder_type: 'business'
  }
  try {
    const response = await bankAPI.getWidgetToken(payload)
    console.log(response)
    const widgetToken = response.data.widget_token // Corrected line
    main(widgetToken, businessId)
  } catch (error) {
    console.log(error)
  }
}
