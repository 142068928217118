export const contribuyentes = [
    {
      nombre: 'Sociedad Anónima Abierta',
    },
    {
      nombre: 'Sociedad Anónima Cerrada',
    },
    {
      nombre: 'Sociedad por Acción',
    },
    {
      nombre: 'Soc. Personas 1ª Categoría',
    },
    {
      nombre: 'Empresario Individual (EIRL)',
    },
    {
      nombre: 'Empresario Individual',
    },
    {
      nombre: 'Sociedad de Profesionales',
    },
    {
      nombre: 'Establecimiento Permanente',
    },
    {
      nombre: 'Comunidad',
    },
    {
      nombre: 'Cooperativa',
    },
    {
      nombre: 'Org. sin Fines de Lucro',
    },
    {
      nombre: '14 A Régimen Semi Integrado',
    },
    {
      nombre: '14 D N°3 Régimen Pro Pyme General',
    },
    {
      nombre: '14 D N°8 Régimen Pro Pyme Transp.',
    },
    {
      nombre: 'Rentas Presuntas',
    },
    {
      nombre: '14 B N°1 Renta efectiva sin Balance',
    },
    {
      nombre: 'No sujeto art. 14 LIR',
    },
    {
      nombre: 'Soc. Prof. 1ra Categoría',
    },
    {
      nombre: 'Soc. Prof. 2da Categoría',
    },
    {
      nombre: 'Otro',
    },
    {
      nombre: 'Contabilidad Completa',
    },
    {
      nombre: 'Contabilidad Simplificada',
    },
  ]