export const atributos =[
    {
        nombre: 'Importador',
        
    },
    {
        nombre: 'Exportador',
        
    },
    {
        nombre: 'Prestaciones de servicios',
        
    },
    {
        nombre: 'Sucursal en el extranjero',
        
    },
    {
        nombre: 'Opera con franquicias o licencias',
        
    },
    {
        nombre: 'Manejo de proveedores internacionales',
        
    },
    {
        nombre: 'Ofrece servicio técnico',
        
    },
    {
        nombre: 'Cuenta con tienda física',
        
    },
    {
        nombre: 'Utiliza software ERP',
        
    },
    {
        nombre: 'Publicidad por redes sociales',
        
    },
    {
        nombre: 'Realiza importaciones bajo régimen de Zona Franca',
        
    },
    {
        nombre: 'Cuenta con certificaciones de calidad (ISO, HACCP, etc.)',
        
    },
    {
        nombre: 'Desarrollo de productos propios (R&D)',
        
    },
    {
        nombre: 'Participa en licitaciones públicas (ChileCompra)',
        
    },
    {
        nombre: 'Cumple con normativas ambientales',
        
    },
    {
        nombre: 'Realiza consultorías',
        
    },
    {
        nombre: 'Manejo de inventario',
        
    },
    {
        nombre: 'Participa en ferias o eventos comerciales',
        
    },
    {
        nombre: 'Afiliación a cámaras de comercio',
        
    },
    {
        nombre: 'Manejo de leasing o arriendo de activos',
        
    },
]