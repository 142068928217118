const tipoDocumento = [
  { value: 33, label: 'Factura Electrónica' },
  { value: 34, label: 'Factura no Afecta o Exenta Electrónica' },
  { value: 43, label: 'Liquidación-Factura Electrónica' },
  { value: 61, label: 'Nota de Crédito Electrónica' },
  { value: 914, label: 'Declaración de Ingreso' },
  { value: 39, label: 'Total Oper. del mes Boleta Electr.' },
  { value: 48, label: 'Total mes Comprobantes Pago Electrónico' },
  { value: 110, label: 'Factura de Exportación Electrónica' },
  { value: 45, label: 'Factura de Compra' },
  {value : 0, label: 'Boletas de Honorarios'}
]

export { tipoDocumento }
