import { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { applyActionCode, Auth } from "firebase/auth";
import { auth } from "../../../firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UserAuth } from "../../../context/AuthContext";


export const ActionAuth: FC = () => {
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, isLoaded } = UserAuth();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleEmailVerification = (auth: Auth, oobCode: string) => {
    applyActionCode(auth, oobCode)
      .then(() => {
        toast.success('Correo exitosamente verificado', {
          onClose: () => {
            toast.success('Ahora completa tu registro');
            navigate('/auth/complete-signup', { replace: true });
          },
        });
      })
      .catch((error) => {
        setError(error.code);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const oobCode = urlParams.get('oobCode') ?? '';
    const mode = urlParams.get('mode') ?? '';
    if (!oobCode && !mode) {
      navigate('/auth/signin', { replace: true });
      return;
    }
    if (mode === 'resetPassword') {
      navigate(`/auth/complete-reset-password?mode=${mode}&oobCode=${oobCode}`, { replace: true });
      return;
    }
    if (mode === 'verifyEmail') {
      handleEmailVerification(auth, oobCode);
      return;
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (user?.emailVerified && error) {
        toast.success('Correo exitosamente verificado', {
          onClose: () => {
            toast.success('Ahora completa tu registro');
            navigate('/auth/complete-signup', { replace: true });
          },
        });
      } else if (!user?.emailVerified && error) {
        toast.error('No se pudo verificar el correo', {
          onClose: () => {
            toast.info('Intenta reenviar el correo nuevamente');
            navigate('/auth/signup');
          },
        });
      }
    }
  }, [user, error, isLoaded])

  return (
    <div className='flex flex-col gap-6 justify-between mb-12 bg-white rounded-lg shadow-md px-7 py-9 w-[460px]'>
      <div className='mb-6'>
        <h3 className='mb-3 text-3xl font-bold text-center'>
          {isLoading ?
            'Cargando...'
            : error && !user.emailVerified ? ('Error, Lo sentimos')
              : (
                urlParams.get('mode') === 'verifyEmail' ?
                  'Correo Verificado'
                  : 'Reestablecer contraseña'
              )}
        </h3>

        <p>
          Tu cuenta esta casi lista completa los siguientes pasos
          para terminar el proceso.
        </p>
      </div>

      <div>
        <button
          disabled={true}
          type='button'
          className='btn-primary rounded-full'
        >
          <span>Cargando</span>
          <FontAwesomeIcon spin={true} icon={faCircleNotch} />
        </button>
      </div>
    </div>
  )
}
