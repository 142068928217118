import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import imgCheck from '../../../assets/iconos/dobleCheckGris.png'
import { useState, useEffect, useContext } from 'react'
import GlobalContext from '../../../context/global-context'
import { OrganizationAPI } from '../../../api/organization.api'

const optionsKeys = [
  { name: 'Urgentes' },
  { name: 'Rutinarias' },
  { name: 'SII' },
  { name: 'Calendario' }
]

const Notifications = ({ onClose, setHasNotifications }) => {
  const [selectedFilter, setSelectedFilter] = useState('Rutinarias')
  const { currentBusiness } = useContext(GlobalContext)
  const organizationAPI = new OrganizationAPI()
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)  

  useEffect(() => {
    let isMounted = true

    const fetchNotification = async () => {
      try {
        const response = await organizationAPI.getNotification({ businessId: currentBusiness.id })
        if (isMounted) { 
          if (Array.isArray(response.data)) {
            const extractedNotifications = response.data.map(item => ({
              message: item.notification
            }))
            setNotifications(extractedNotifications)
            setHasNotifications(extractedNotifications.length > 0)
          } else {
            console.error('data no es array:', response.data)
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchNotification()

    return () => {
      isMounted = false
    }
  }, [currentBusiness.id])

  const handleClose = () => {
    onClose()
    if (notifications.length === 0) {
      setHasNotifications(false)
    }
  }

  const HeaderTitle = () => (
    <header className='mb-8 h-[60px] flex items-center justify-between border-b-2 border-slate-300'>
      <h2 className='text-xl font-bold'>Notificaciones</h2>

      <button
        className='hover:scale-110 active:scale-100 transition-all'
        type='button'
        onClick={handleClose}
      >
        <FontAwesomeIcon size='lg' className='text-slate-500/85' icon={faX} />
      </button>
    </header>
  )

  const BarFilterSearch = () => (
    <ul className='absolute flex text-sm justify-between items-center px-5 py-2 shadow-xl top-[88%] left-[32%] bg-primary text-slate-50 min-h-[48px] rounded-full'>
      {optionsKeys.map((selectedFilterKey, i) => (
        <li key={i}>
          <button
            type='button'
            className={`transition-all p-[10px] rounded-full hover:bg-white/35 ${
              selectedFilter === selectedFilterKey.name
                ? 'font-bold text-white bg-white/25'
                : 'text-slate-50'
            }`}
            onClick={() => setSelectedFilter(selectedFilterKey.name)}
          >
            {selectedFilterKey.name}
          </button>
        </li>
      ))}
    </ul>
  )

  return (
    <div className='ml-44 text-base p-8 pt-3 shadow-xl rounded-2xl bg-gray-100 w-[888px] relative'>
      <HeaderTitle/>
      <section className='flex flex-col overflow-hidden bg-white rounded-2xl pl-6 pr-0 shadow-md mb-10 py-10'>
        {loading ? (
          <div className='flex justify-center items-center h-full'>
            <p className='text-gray-500'>Cargando datos...</p>
          </div>
        ) : selectedFilter === 'Rutinarias' ? (
          <>
            <h1 className='font-bold'>Servicio de Impuestos Internos</h1>
            <h4 className='italic'>Tenemos algunos recordatorios para ti.</h4>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <table className='min-w-full'>
                <tbody>
                  {notifications.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                      <td className='px-4 py-2'>{item.message}</td>
                      <td className='px-4 py-2'>
                        <img className='w-10 h-10 shadow-md rounded-full' src={imgCheck} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className='h-full flex items-center justify-center'>
            <p className='text-gray-500'>No hay notificaciones para mostrar.</p>
          </div>
        )}
      </section>
      <BarFilterSearch />
    </div>
  )
}


export default Notifications