import { Form, Formik } from 'formik'
import { useLucaSignUp } from '../hooks/useLucaSignUp'
import { FieldWithErrorMessage } from '../../../ui/FieldWithErrorMsg'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useMutationCreateOrganization } from '../queries/organizationQueries'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { UserAuth } from '../../../../../context/AuthContext'
import { useSwiper } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { BaseHTTP } from '../../../../../api/base.http'
import { useLucaAuth } from '../hooks/useAuth'
import tituloLoginSII from '../../../../../assets/tituloLoginSII.png'

const step4ValidationSchema = Yup.object({
  rut: Yup.string().required('Ingresa tu rut'),
  siiPassword: Yup.string().required('Ingresa tu clave tributaria')
})

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export function StepForm4() {
  const deleteApiToken = useLucaAuth(state => state.deleteApiToken)
  const { setIsAuthenticated } = UserAuth()
  const setAccountProfile = useLucaSignUp(state => state.setAccountProfile)
  const { apiToken } = useLucaAuth(state => state.accountProfile)
  const accountProfile = useLucaSignUp(state => state.accountProfile)
  const navigate = useNavigate()
  const organizationMutation = useMutationCreateOrganization()
  const [isLoading, setIsLoading] = useState(false)
  const swiper = useSwiper()

  const step4Values = {
    rut: accountProfile?.rut || '',
    siiPassword: accountProfile?.siiPassword || ''
  }

  /**
   *
   * @param {Step1Values} values
   * @param {import('formik').FormikHelpers < Step1Values >} actions
   */
  const handleFinalStepForm = async (values, actions) => {
    setAccountProfile(values)
    const ACCOUNT_PROFILE_PAYLOAD = {
      ...accountProfile,
      ...values
    }
    try {
      setIsLoading(true)
      actions.resetForm()
      await toast.promise(createOrganizationAccount(ACCOUNT_PROFILE_PAYLOAD), {
        pending: 'Creando tu organización... 🚀',
        success: 'Organización creada exitosamente 👌',
        error: 'No se pudo crear tu organización 🤯'
      })
      actions.setSubmitting(true)

      setTimeout(() => navigate('/', { replace: true }), 400)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const createOrganizationAccount = async values => {
    const organizationPayload = {
      name: values.companyName,
      userType: values.userType,
      siiCredentials: { user: values.rut, password: values.siiPassword }
    }
    BaseHTTP.getInstance().setBackendToken(apiToken)
    const organizationData = await organizationMutation.mutateAsync(organizationPayload)
    BaseHTTP.getInstance().setBackendToken(organizationData.token)
    setIsAuthenticated(true)
    setTimeout(() => {
      deleteApiToken()
    }, 10_000)
  }

  return (
    <Formik
      validationSchema={step4ValidationSchema}
      initialValues={step4Values}
      onSubmit={handleFinalStepForm}
    >
      {({ isSubmitting }) => (
        <Form className='px-1'>
          <div className='mb-2 flex justify-center bg-primary h-[80px] py-3'>
              <img src={tituloLoginSII} alt='tituloLoginSII' />
            </div>

          <ul className='grid grid-cols-2 gap-x-8 gap-y-3'>
            <li className='col-span-2'>
              <FieldWithErrorMessage
                name='rut'
                type='text'
                placeholder='Ej 12345678-9'
                label='Rut'
              />
            </li>
            <li className='col-span-2'>
              <FieldWithErrorMessage
                name='siiPassword'
                type='password'
                placeholder='Ingresa clave'
                label='Ingresa clave tributaria'
              />
            </li>
          </ul>

          <div className='flex flex-col gap-2 mt-8 w-2/3 mx-auto'>
            <div className='flex items-center justify-center gap-8'>
              <button
                disabled={isSubmitting}
                onClick={() => swiper.slidePrev()}
                type='button'
                className='btn-light-alt rounded-full'
              >
                <FontAwesomeIcon size='lg' icon={faArrowLeft} />
                <span>Volver</span>
              </button>
              <button disabled={isSubmitting} type='submit' className='btn-primary rounded-full'>
                {isLoading ? (
                  <span>Creando...</span>
                ) : (
                  <>
                    <span>Continuar</span>
                    <FontAwesomeIcon size='lg' icon={faArrowRight} />
                  </>
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
