export const optionTipoComprobante = [
    { label: 'Ingreso', value: 1 },
    { label: 'Egreso', value: 2 },
    { label: 'Traspaso', value: 3 },
]

export const optionEstado = [
    { label: 'Aprobado', value: 1 },
    { label: 'Borrador', value: -6 },
]

//export const cuentaAux = [54,62,118,119,120,121,128,60,61]
export const cuentaAux = ['1-1-4-1', '1-1-6-3', '2-1-4-1', '2-1-4-2', '2-1-4-3', '2-1-4-4', '2-1-6-1', '1-1-6-1', '1-1-6-2']
export const editLuca = ['3-1-1-1', '3-1-2-1', '3-2-1-1', '3-2-1-9']
//'1-1-8-1', '1-1-8-2', '1-1-8-3', '1-2-1-1', '1-2-2-1', '1-2-2-2', '1-2-2-3', '1-2-3-1', '1-2-3-2', '1-2-3-3', '1-2-3-4','1-2-4-1', '1-1-8-2', 
export const usuarioLuca = 
['3-2-2-1','3-2-4-1','3-2-4-2','3-2-4-3','3-2-4-4','3-2-4-5','3-2-4-6','3-2-4-7','3-2-4-8','3-2-4-9'
,'3-2-4-10','3-2-4-11','3-2-4-12','3-2-4-13','3-2-4-14','3-2-4-15','3-2-4-16','3-2-4-17','3-2-4-18','3-2-4-19','3-2-4-20'
,'3-2-4-21','3-2-4-22','3-2-4-23' ,'3-2-4-24','3-2-4-25','3-2-4-26','3-2-4-27','3-2-4-28','3-2-4-29','3-2-4-30','3-2-4-31'
,'3-2-4-32' ,'3-2-4-33','3-2-4-99','3-2-5-1','3-2-5-2','3-2-5-3','3-2-5-4' ,'3-2-5-9']
