import { useContext, useState, ReactNode } from 'react';
import GlobalContext from './global-context';
import { IGlobalContext, ILoader, IDialog, IDialogForm } from '../models/Global-Context';
import { IBusiness } from '../models/Business';

interface GlobalContextProviderProps {
  children: ReactNode;
}

const GlobalContextProvider: React.FC<GlobalContextProviderProps> = (props) => {
  const [business, setBusiness] = useState<IBusiness | null>(null);
  const [currentBusiness, setCurrentBusiness] = useState<IBusiness | undefined>(undefined);
  const [loader, setLoader] = useState<ILoader>({ visible: false, text: '' });
  const [currentBank, setCurrentBank] = useState<string | undefined>(undefined);

  // Manejo de periodos bloqueados
  const [blockedPeriods, setBlockedPeriods] = useState<{ periodo: string; estado: boolean }[]>(
    [] // Inicialmente vacío
  );

  // Alternar el estado de un periodo específico
  const toggleBlockedPeriod = (periodo: string) => {
    setBlockedPeriods((prev) => {
      const index = prev.findIndex((p) => p.periodo === periodo);
      if (index !== -1) {
        // Si existe, alternar estado
        return prev.map((p, i) => (i === index ? { ...p, estado: !p.estado } : p));
      } else {
        // Si no existe, agregar con estado bloqueado
        return [...prev, { periodo, estado: true }];
      }
    });
  };

  // Bloquear o desbloquear todos los periodos
  const toggleAllPeriods = (estado: boolean) => {
    setBlockedPeriods((prev) =>
      prev.map((p) => ({ ...p, estado }))
    );
  };

  // Verificar si un periodo específico está bloqueado
  const isPeriodBlocked = (periodo: string): boolean => {
    const found = blockedPeriods.find((p) => p.periodo === periodo);
    return found ? found.estado : false;
  };

  const [dialog, setDialog] = useState<IDialog>({
    title: '',
    body: '',
    btnText: '',
    open: false,
  });

  const [dialogForm, setDialogForm] = useState<IDialogForm>({
    title: '',
    body: '',
    btnTextClose: '',
    btnTextOpen: '',
    open: false,
  });

  const contextValue: IGlobalContext = {
    ui: {
      loader,
      setLoader,
      dialog,
      setDialog,
      dialogForm,
      setDialogForm,
      blockedPeriods, // Nueva estructura
      setBlockedPeriods,
      toggleBlockedPeriod,
      toggleAllPeriods,
      isPeriodBlocked,
    },
    business,
    setBusiness,
    currentBusiness,
    setCurrentBusiness,
    currentBank,
    setCurrentBank,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;

export const useGlobalContext = () => useContext(GlobalContext);
