import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

const StyledSelect = styled(Select)(({ theme }) => ({
  height: '30px',
  borderRadius: '16px',
  minWidth: '200px',
  padding: '0 12px',
  backgroundColor: theme.palette.background.default,
  fontSize: '0.875rem',
  fontFamily: theme.typography.fontFamily,
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '32px', // espacio para el icono de flecha
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[400],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[500],
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
}));

const FilterControl = ({ label, value, onChange, options = [], disabled = false }) => {
  return (
    <FormControl
      variant="outlined"
      style={{
        marginBottom: '0',
        alignItems: 'center',
        minWidth: '150px',
      }}
    >
      <InputLabel
        shrink={true} // Levanta el texto sobre la línea
        style={{
          fontSize: '0.75rem',
          fontWeight: 500,
          color: 'gray',
          backgroundColor: 'transparent', // Fondo transparente
          transform: 'translate(12px, -15px)', // Levantar más el texto
          padding: '0 4px', // Espacio alrededor del texto
        }}
      >
        {label}
      </InputLabel>
      <StyledSelect
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        renderValue={(selected) => {
          const selectedOption = options.find((option) => option.value === selected);
          return selectedOption ? selectedOption.label : '';
        }}
        variant="outlined"
        color="primary"
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default FilterControl;
