import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../../../context/AuthContext';
import { StepForm2 } from './Step2Form';
import { StepForm3 } from './Step3Form';
import { StepForm4 } from './Step4Form';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SecurityTSL } from '../../components/SignUpLayout';
import { useLucaAuth } from '../hooks/useAuth';
import recurso3 from '../../../../../assets/imagenes/Recurso 3.svg'
import recurso4 from '../../../../../assets/imagenes/Recurso 4.svg'
import recurso5 from '../../../../../assets/imagenes/Recurso 5.svg'
import recurso6 from '../../../../../assets/imagenes/Recurso 6.svg'
import recurso7 from '../../../../../assets/imagenes/Recurso 7.svg'
import recurso8 from '../../../../../assets/imagenes/Recurso 8.svg'


// step forms accountProfile sign up
const stepForms = [
  <StepForm2 key={1} />,
  <StepForm3 key={2} />,
  <StepForm4 key={3} />,
]

export const CompleteSignUp: FC = () => {
  const navigate = useNavigate()
  const { apiToken } = useLucaAuth(state => state.accountProfile)
  const { user, isAuthenticated, isLoaded } = UserAuth()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!user && !apiToken && isLoaded) {
      navigate('/auth/signup', { replace: true })
    }
  }, [user, isAuthenticated, isLoaded])

  const imageSets = {
    0: [recurso3, recurso4, recurso5, recurso6],
    1: [recurso3, recurso7, recurso5, recurso6],
    2: [recurso3, recurso7, recurso8, recurso6],
  }
  
  const renderImages = () => {
    const images = imageSets[activeIndex]
    if (!images) return null
  
    return (
      <div className='flex items-center justify-center gap-4 mb-6'>
        {images.map((src, index) => (
          <img key={index} src={src} className='w-20 h-20' />
        ))}
      </div>
    )
  }

  return (
    <>
    <div className='flex justify-center items-start gap-8'>
      <div className='flex flex-col items-center'>
        <div className='w-full flex justify-center mb-4'>
          {renderImages()}
        </div>
  
        <div className='relative px-7 py-9 w-[520px] min-h-[460px] bg-white rounded-lg shadow-md'>
          <Swiper
            onSlideChange={(sw) => setActiveIndex(sw?.activeIndex)}
            spaceBetween={30}
            allowTouchMove={false}
          >
            {stepForms.map((step, index) => (
              <SwiperSlide className='mb-1' key={index}>
                {step}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
       { /*{activeIndex === stepForms.length - 1 ? <SecurityTSL /> : null}*/}
      </div>
          
    </div>
    {activeIndex === stepForms.length - 1 ? (
          <div className='w-1/3 flex justify-center items-center min-h-full'>
            <SecurityTSL />
          </div>
          ) : null}
    </>
  )
}
