import { FC, useEffect, useState } from 'react'
import { FieldWithOutLabel } from '../../../../../modules/ui/FieldWithOutLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useQueryGetRoles } from '../../../../../../queries/roleQueries'
import { SelectFieldWithSearch } from '../../../../../modules/ui/SelectWithSearch'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { useMutationInviteUser } from '../../../../../../queries/userQueries'
// import { useGlobalContext } from '../../../../../../context/GlobalState'
// import { IBusiness } from '../../../../../../models/Business'
import monita from '../../../../../../assets/imagenes/monita.svg';

interface FormValues {
  email: string
  role?: {
    name: string
    id: number
  }
}

const schemmaValidation = Yup.object<FormValues>({
  email: Yup.string().email('Ingresa un correo valido').required('No puede estar vacio'),
  role: Yup.object({
    name: Yup.string().required('No puede estar vacio'),
  })
})


export const FormInviteUser: FC = () => {
  // const { currentBusiness } = useGlobalContext()
  const { data: roles = [], isSuccess } = useQueryGetRoles()
  // const { data: userMe } = useQueryGetUserMe()
  const [initialValues, setInitialValues] = useState<FormValues>({ email: '' });
  const inviteUser = useMutationInviteUser()

  useEffect(() => {
    if (isSuccess && roles) {
      setInitialValues({
        email: '',
        role: {
          name: roles[1]?.name,
          id: roles[1]?.id
        }
      })
    }
  }, [isSuccess])

  return (
    <div className='flex flex-col gap-2'>
      <Formik
        enableReinitialize={true}
        validationSchema={schemmaValidation}
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (values.role?.id) {
            const promise = inviteUser.mutateAsync(values)
            try {
              await toast.promise(promise, {
                pending: 'Invitando usuario... 🚀',
                success: {
                  render() {
                    return 'Hemos enviado un correo de invitación 👌'
                  },
                  onClose() {
                    toast.success('Hazle saber a tu invitado que complete su registro')
                  },
                },
                error: {
                  render(error) {
                    const myError = error.data as AxiosError<{ message: string }>;
                    if (myError.response?.status) {
                      return myError.response?.data.message;
                    }
                    return 'No se pudo invitar al usario 🤯';
                  }
                }
              })
            } catch (error) {
              return null
            }
          }
        }}
      >
        <Form>
          <ul className='grid-cols-2 ml-2 gap-x-7 gap-y-2'>
            {/* <li>
              <div>
                <h4 className='font-bold'>Nombres y Apellidos</h4>
                <p>{userMe?.firstname} {userMe?.lastname}</p>
              </div>
            </li> */}

            <li className='w-56'>
              <FieldWithOutLabel
                name='email'
                placeholder='Ingresa Correo Electronico'
                type='email'
              />
            </li>

            {/* <li>
              <div>
                <h4 className='font-bold'>Perfil</h4>
                <p>{userMe?.role?.name}</p>
              </div>
            </li> */}

            <li className='w-56'>
              <SelectFieldWithSearch
                label='Rol'
                name='role'
                placeholder='Role'
                options={roles?.map(role => ({
                  id: role?.id,
                  name: role?.name
                }))}
                secondaryButton={{ text: 'Crear nuevo rol' }}
                
              />
            </li>

            {/* <li>
              <div>
                <h4 className='font-bold'>Rut</h4>
                <p>{(currentBusiness as any as IBusiness)?.rut}</p>
              </div>
            </li> */}

            <button type='submit' className='btn-accent w-56 mt-4'>
              <span>INVITAR</span>
              <FontAwesomeIcon size='lg' icon={faPaperPlane} />
            </button>
            <img
          src={monita}
          alt="Invitar nuevo usuario"/>
          </ul>
        </Form>
      </Formik>
    </div>
  )
}
