import { useState, useEffect, useContext } from 'react'
import { HeadingTitle, Paper} from '../../../components/modules/ui/index'
import Select from '../../../components/modules/ui/Select'
import {
    currentMonth,
    currentYear,
  } from '../../../components/utils/constant'
import CustomButton from '../../../components/modules/ui/CustomButton'
import Table from './Table'
import GlobalContext from '../../../context/global-context'
import { BusinessAPI } from '../../../api/business.api'

const ListEstadoDeResultados = () => {
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [yearFilter, setYearFilter] = useState(currentYear)
  const { ui, currentBusiness } = useContext(GlobalContext)
  const businessAPI = new BusinessAPI()
  const [data, setData] = useState([])

  /*const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))*/

  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
      await businessAPI
        .getEstadoResultado({
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter
        })
        .then(({ data }) => {
          setData(data)
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No dato')
    }
  }

  useEffect(() => {
    getData()
  }, [currentBusiness])
    
  const handleMonthYearChange = (e) => {
    const [year, month] = e.target.value.split('-');
    setMonthFilter(month);
    setYearFilter(year);
  }

  return (
    <>
    <Paper>
        <HeadingTitle title='Estado de Resultados'/>
        <div>
          <div className='flex gap-2 items-end'>
            <div className='flex flex-col'>
              <label>Fecha</label>
              <input
                type='month'
                value={`${yearFilter}-${monthFilter}`}
                onChange={handleMonthYearChange}
                className='w-full border border-gray-300 rounded-full px-2 py-1 text-sm'
              />
            </div>
            <div className='flex flex-col'>
              <label className='text-sm mb-1'>Centro de Costo</label>
              <Select label='Centro de Costo' value={''} options={[]} disabled />
            </div>
            <div className='flex flex-col'>
              <label className='text-sm mb-1'>Sucursal</label>
              <Select label='Sucursal' value={''} options={[]} disabled />
            </div>
            <CustomButton
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={getData}
            />
          </div>
        </div>
        <Table 
          rows={data}
          getData={getData}
        />
    </Paper>
    </>
  )
}

export default ListEstadoDeResultados
