import { useState, useContext } from 'react'
import GlobalContext from '../../../../../../../context/global-context'
import { toast } from 'react-toastify'

// MUI
import { Backdrop } from '@mui/material'

// components
import { HeadingTitle, Paper, Text, Button, Select } from '../../../../../ui/index'
import TableMovements from './Table'
import { ExportCSV, ExportXLS } from '../../../../../table/ExportData'
import ExportPDFBankMovements from '../../../../../table/typesExportPDF/ExportPDFbankMovements'

// api
import { BankAPI } from '../../../../../../../api/bancos.api'

// utils
import {
  currentMonth,
  currentYear,
  monthsOptions as months,
  yearsOptions
} from '../../../../../../utils/constant'
import { formatCurrency, formatDate } from '../../../../../../utils/formatter'
import FilterPagination from '../../../../../table/FilterPagination'

const List = ({ data, currentAcount }) => {
  console.log(data)
  const [showModal, setShowModal] = useState(false)
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [transactionsAcount, setTransactionsAcount] = useState([])
  const [yearFilter, setYearFilter] = useState(currentYear)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { currentBusiness } = useContext(GlobalContext)
  const bankAPI = new BankAPI()

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const getDataListTransactionsAcount = async () => {
    try {
      const limit = 1000;
      const formattedMonth = monthFilter.toString().padStart(2, '0'); // Asegura dos dígitos en el mes
  
      // Validaciones de `currentAcount` y `currentBusiness`
      const productId = currentAcount?.id || 0; // Si no hay cuenta seleccionada, usa 0 para todas
      if (!currentBusiness || !currentBusiness.id) {
        console.error('currentBusiness o su id no están definidos.');
        return; // Salir si no hay un negocio válido
      }
  
      // Llamar al API con valores validados
      const responseGetDataListTransactionsAcount = await bankAPI.getTransactionsSummary(
        productId, // ID de la cuenta (o 0)
        currentBusiness.id, // ID del negocio
        limit, // Límite de registros
        yearFilter, // Año
        parseInt(formattedMonth) // Mes formateado como número
      );
  
      // Actualizar estado con los datos
      setTransactionsAcount(responseGetDataListTransactionsAcount.data);
    } catch (error) {
      console.error(
        `Error al obtener movimientos (productoId=${currentAcount?.id}, businessId=${currentBusiness?.id}):`,
        error.response?.data || error.message
      );
      setTransactionsAcount([]); // Estado vacío en caso de error
    }
  };

  const handleSearchMovements = async () => {
    await toast.promise(getDataListTransactionsAcount(), {
      pending: 'Cargando datos... 🚀',
      success: 'Datos cargados exitosamente... 👌',
      error: 'No se pudieron cargar los datos 🤯'
    })
  }

  // const dataToExport = transactionsAcount?.map(item => {
  //   return {
  //     'Usuario Asignador Id': item.usuarioAsignadorId ? item.usuarioAsignadorId : 'id sin definir',
  //     'Usuario Asignador': item.usuarioAsignador ? item.usuarioAsignador : 'usuario sin definir',
  //     'Nombre Banco': item.nombreCuentaBanco,
  //     id: item.id,
  //     Fecha: item.fecha && formatDate(item.fecha),
  //     Abono: formatCurrency(item.montoAbono),
  //     Cargo: formatCurrency(item.montoCargo),
  //     'Numero Cuenta': item.numeroCuentaBanco,
  //     descripcion: item.descripcion,
  //     Status: item.status,
  //     'Fecha pago': item.fechaPago ? formatDate(item.fechaPago) : 'fecha sin definir',
  //     Aprobado: item.aprobado ? 'Si' : 'No'
  //   }
  // })

  const dataToExport = transactionsAcount?.map(item => ({
    'Usuario Asignador Id': item.usuarioAsignadorId || 'ID no definido',
    'Usuario Asignador': item.usuarioAsignador || 'Usuario no definido',
    'Nombre Banco': item.nombreCuentaBanco || 'Banco no definido',
    'ID': item.id || 'ID no definido',
    'Fecha': item.fecha ? formatDate(item.fecha) : 'Fecha no definida',
    'Monto Abono': item.montoAbono ? formatCurrency(item.montoAbono) : formatCurrency(0),
    'Monto Cargo': item.montoCargo ? formatCurrency(item.montoCargo) : formatCurrency(0),
    'Número de Cuenta': item.numeroCuentaBanco || 'Cuenta no definida',
    'Descripción': item.descripcion || 'Sin descripción',
    'Estado': item.status || 'Estado no definido',
    'Fecha de Pago': item.fechaPago ? formatDate(item.fechaPago) : 'Fecha no definida',
    'Aprobado': item.aprobado ? 'Sí' : 'No'
  })) || [];

  const icons = [
    <ExportCSV
      key='export-csv'
      data={dataToExport}
      title='bancarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportXLS
      key='export-xls'
      data={dataToExport}
      title='bancarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportPDFBankMovements
      key='export-pdf'
      data={transactionsAcount}
      setShowModal={setShowModal}
      title='bancarios'
      year={yearFilter}
      month={monthFilter}
    />
  ]

  const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))

  return (
    <>
      <Paper>
        <HeadingTitle title='Resumen de movimientos' rightIcons={icons} iconHeight={32} />
        <div>
          <div className='flex gap-2 items-end'>
            <Select
              options={monthsOptions}
              defaultValue={monthFilter}
              onChange={e => setMonthFilter(e.target.value)}
            />
            <Select
              // TODO: Eliminar filter luego de presentar
              options={yearsOptions}
              defaultValue={yearFilter}
              onChange={e => setYearFilter(e.target.value)}
            />
            <FilterPagination rowsPerPage={rowsPerPage} handleChangeRowsPerPage={setRowsPerPage} />
            <Button
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={e => handleSearchMovements(e, yearFilter, monthFilter)}
            />
          </div>
        </div>
        <TableMovements
          rows={data || transactionsAcount}
          getData={getDataListTransactionsAcount}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}

export default List