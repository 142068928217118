import { useState, useContext } from 'react'
import GlobalContext from '../../../../../../context/global-context'

//components
import { TablePresupuesto, TableAnualPresupuesto } from './Table'
import { Paper, SearchInput, Button } from '../../../../ui/index'

//api
import { TablesAPI } from '../../../../../../api/table.api'

// Utilidades de cálculo
const calcularMedia = array => {
  const suma = array.reduce((acc, val) => acc + val, 0)
  return suma / array.length
}

const calcularDesviacionEstandar = (array, media) => {
  const sumaDesviaciones = array.reduce((acc, val) => acc + Math.pow(val - media, 2), 0)
  return Math.sqrt(sumaDesviaciones / array.length)
}

const generarValorAleatorioEnRango = (min, max) => {
  return Math.random() * (max - min) + min
}

const PresupuestoTableInsights = () => {
  const [selectedOption, setSelectedOption] = useState('M')
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [dataProcesada, setDataProcesada] = useState([])
  const tablesAPI = new TablesAPI()

  const processHistoricalData = data => {
    const processedData = data
      .map((array, index) => {
        const arrayFiltrado = array.filter(val => val !== 0)

        if (arrayFiltrado.length > 0) {
          const media = calcularMedia(arrayFiltrado)
          const desviacionEstandar = calcularDesviacionEstandar(arrayFiltrado, media)
          const min = media - desviacionEstandar
          const max = media + desviacionEstandar
          const valorAleatorio = generarValorAleatorioEnRango(min, max)

          return {
            id: index + 1,
            name: `Item ${index + 1}`, // Ajusta según el nombre real de los items
            media: media,
            desviacionEstandar: desviacionEstandar,
            valorAleatorio: valorAleatorio
          }
        } else {
          console.warn(`El array con id ${index + 1} está vacío después de filtrar.`)
          return null
        }
      })
      .filter(item => item !== null) // Filtrar los sub-arrays vacíos después de procesamiento

    // Sumar todos los valores aleatorios
    const sumaValoresAleatorios = processedData.reduce((acc, item) => acc + item.valorAleatorio, 0)

    // Crear el nuevo objeto con id 13
    const nuevoObjeto = {
      id: 13,
      name: 'Total Aleatorio',
      valorAleatorio: sumaValoresAleatorios
    }

    // Agregar el nuevo objeto al array de dataProcesada
    processedData.unshift(nuevoObjeto)

    setDataProcesada(processedData)
  }

  const handleOptionClick = option => {
    setSelectedOption(option)
  }

  const handleOnClick = async () => {
    try {
      if (currentBusiness.id) {
        ui.setLoader({ visible: true, text: 'Cargando los datos en la tabla...' })
        const response = await tablesAPI.getDataHistoricalSales(currentBusiness.id)
        processHistoricalData(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      ui.setLoader({ visible: false, text: '' })
    }
  }

  return (
    <div>
      <div className='flex items-center mt-8 gap-3'>
        <select
          className='w-fit h-9 appearance-none focus:outline-none focus:ring-0 block border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-3xl shadow leading-tight '
          name='validation'
          id='validation'
        >
          <option>Presupuesto año 2024</option>
          <option>Presupuesto año 2025</option>
          <option>Presupuesto año 2026</option>
        </select>
        <div className='flex items-center bg-primary rounded-full px-2 py-2 text-white'>
          <div
            className={`cursor-pointer p-1 rounded-full ${
              selectedOption === 'M' ? 'bg-white text-blue-900' : ''
            }`}
            onClick={() => handleOptionClick('M')}
          >
            M
          </div>
          <div
            className={`cursor-pointer p-1 rounded-full ${
              selectedOption === 'Q' ? 'bg-white text-blue-900' : ''
            }`}
            onClick={() => handleOptionClick('Q')}
          >
            Q
          </div>
          <div
            className={`cursor-pointer p-1 rounded-full ${
              selectedOption === 'A' ? 'bg-white text-blue-900' : ''
            }`}
            onClick={() => handleOptionClick('A')}
          >
            A
          </div>
        </div>
      </div>
      <Paper>
        <div className='flex items-center justify-between mb-3'>
          <div className='flex items-center justify-between gap-8'>
            <SearchInput placeholder='Buscar por cuenta o monto' classNameBorder='rounded-full' />
            <div>
              <input id='hideLine' type='checkbox' className='rounded mr-3' />
              <label htmlFor='hideLine'>esconder lineas vacias</label>
            </div>
          </div>
          <div className='flex items-center justify-end gap-2'>
            <Button
              onClick={handleOnClick}
              className='uppercase text-sm text-white bg-accent2 rounded-full font-bold'
              title='Presupuesto asistido'
            />
            <Button
              className='uppercase text-sm text-white bg-accent2 rounded-full font-bold'
              title='guardar'
            />
          </div>
        </div>
        {selectedOption === 'M' && <TablePresupuesto data={dataProcesada} />}
        {selectedOption === 'A' && <TableAnualPresupuesto data={dataProcesada} />}
      </Paper>
    </div>
  )
}

export default PresupuestoTableInsights
