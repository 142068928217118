import clsx from 'clsx';

const FilterDateControl = ({ label, value, onChange, className, disabled = false }) => {
//  const currentYear = new Date().getFullYear();
//  const minDate = `${currentYear}-01-01`;
  const maxDate = new Date().toISOString().split('T')[0];

  return (
    <div className={clsx("relative flex flex-col items-start", className)}>
      {label && (
        <label
          htmlFor={`date-input-${label}`}
          className="absolute top-[-15px] left-4 bg-transparent px-1 text-xs font-medium text-gray-600"
        >
          {label}
        </label>
      )}
      <input
        id={`date-input-${label}`}
        type="date"
        value={value || maxDate} // Si no hay valor recibido, usa la fecha actual
        onChange={(e) => onChange(e.target.value)}
        //min={minDate}
        max={maxDate}
        className={clsx(
          "custom-date-picker w-full h-8 px-4 rounded-full border border-gray-300 bg-white text-sm font-sans focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500",
          { "opacity-50 cursor-not-allowed": disabled }
        )}
        disabled={disabled}
      />
      <style>
        {`
          .custom-date-picker::-webkit-calendar-picker-indicator {
            margin-right: 8px;
            cursor: pointer;
          }

          .custom-date-picker:hover {
            border-color: #bbb;
          }

          .custom-date-picker:focus {
            outline: none;
            border-color: #3f51b5;
          }

          /* Mes, día y año en negro */
          .custom-date-picker::-webkit-datetime-edit,
          .custom-date-picker::-webkit-datetime-edit-year-field,
          .custom-date-picker::-webkit-datetime-edit-month-field,
          .custom-date-picker::-webkit-datetime-edit-day-field {
            color: black;
          }
        `}
      </style>
    </div>
  );
};

export default FilterDateControl;
