import { contribuyentes } from "../../../../../data/dataAjustesContribuyentes";

const Contribuyente = () => {
  const column1 = contribuyentes.slice(0, 5);  // Primeros 5 ítems
  const column2 = contribuyentes.slice(5, 10); // Siguientes 5 ítems
  const column3 = contribuyentes.slice(10, 15); // Siguientes 5 ítems
  const column4 = contribuyentes.slice(15, 20); // Últimos 5 ítems

  return (
    <div className="grid grid-cols-3 gap-8">
  {/* Columna izquierda con más espacio (2/3 de la anchura) */}
  <div className="col-span-2 flex flex-col gap-8">
    {/* Tipo de Contribuyente */}
    <div>
      <h3 className="text-sm font-bold mb-2">Tipo de Contribuyente</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          {column1.map((item, index) => (
            <label key={index} className="block mb-2 text-fira-light-table">
              <input type="checkbox" className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none" />
              {item.nombre}
            </label>
          ))}
        </div>
        <div>
          {column2.map((item, index) => (
            <label key={index} className="block mb-2 text-fira-light-table">
              <input type="checkbox" className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none" />
              {item.nombre}
            </label>
          ))}
        </div>
      </div>
    </div>

    {/* Régimen Tributario */}
    <div>
      <h3 className="text-sm font-bold mb-2">Régimen Tributario</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          {column3.map((item, index) => (
            <label key={index} className="block mb-2 text-fira-light-table">
              <input type="checkbox" className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none" />
              {item.nombre}
            </label>
          ))}
        </div>
        <div>
          {column4.map((item, index) => (
            <label key={index} className="block mb-2 text-fira-light-table">
              <input type="checkbox" className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none" />
              {item.nombre}
            </label>
          ))}
        </div>
      </div>
    </div>
  </div>

  {/* Columna derecha con menos espacio (1/3 de la anchura) */}
  <div className="col-span-1">
    <h3 className="text-sm font-bold mb-2">Tipo de Contabilidad</h3>
    <div className="grid grid-cols-1 gap-4">
      <div>
        <label className="block mb-2 text-fira-light-table">
          <input type="checkbox" className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none" />
          Contabilidad Completa
        </label>
      </div>
      <div>
        <label className="block mb-2 text-fira-light-table">
          <input type="checkbox" className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none" />
          Contabilidad Simplificada
        </label>
      </div>
    </div>
  </div>
</div>
  );
};

export default Contribuyente;