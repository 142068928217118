import { TextField} from '@mui/material';


const DatosLegalesEmpresa = ({ selectedBussines }) => {
  const formatDate = (date) => {
    if (!date) return 'Fecha no disponible';
    
    const parsedDate = new Date(date);
    return isNaN(parsedDate) ? 'Fecha no válida' : parsedDate.toLocaleDateString();
  };

  return (
    <div className="p-6 max-w-4xl mx-auto rounded-3xl shadow-lg bg-white">
      {/* Fila 1 */}
      <div className="grid grid-cols-3 gap-6 items-start">
  <div>
    <h3 className="text-xs font-bold text-gray-500">Giro</h3>
    <p className="text-xs text-fira-light font-medium text-gray-500">
      {selectedBussines?.glosaActividad || 'Información no disponible'}
    </p>
    <div className="h-0.5 bg-gray-300 mt-1"></div>
  </div>
  
  <div>
    <h3 className="text-xs font-bold text-gray-500">Constitución Empresa</h3>
    <p className="text-xs text-fira-light font-medium text-gray-500">
      {formatDate(selectedBussines?.fechaConstitucion) || 'Fecha no disponible'}
    </p>
    <div className="h-0.5 bg-gray-300 mt-1"></div>
  </div>

  <div>
    <h3 className="text-xs font-bold text-gray-500">Inicio Actividades</h3>
    <p className="text-xs text-fira-light font-medium text-gray-500">
      {formatDate(selectedBussines?.fechaInicioActividades) || 'Fecha no disponible'}
    </p>
    <div className="h-0.5 bg-gray-300 mt-1"></div>
  </div>
</div>
      {/* Fila 2 */}
      <div className="grid grid-cols-2 gap-6 mt-4 items-start">
        {/* Clasificador de actividades económicas */}
        <div>
    <h3 className="text-xs font-bold text-gray-500">Clasificador de actividades económicas</h3>
    <p className="text-xs text-fira-light font-medium text-gray-500">
      {selectedBussines?.glosaActividad || 'Información no disponible'}
    </p>
    <div className="h-0.5 bg-gray-300 mt-1"></div>
  </div>

        {/* Código */}
        <div>
          <h3 className="text-xs font-bold text-gray-500">Código</h3>
          <TextField
            variant="filled" // Cambia a 'filled' para eliminar el borde
            size="small"
            defaultValue={selectedBussines?.rut || 'Código no disponible'}
            fullWidth
            InputProps={{
              classes: {
                input: "text-xs p-1", // Ajusta el padding para hacerlo más pequeño
              },
              sx: {
                bgcolor: 'transparent', // Fondo transparente
                border: 'none', // Asegura que no haya borde
                height: '32px', // Ajusta la altura del campo
              },
            }}
          />
          <div className="h-0.5 bg-gray-300 mt-1"></div>
        </div>
      </div>

      {/* Fila 3 - Representantes Legales */}
      <div className="grid grid-cols-2 gap-6 mt-4 items-start">
        {/* Representantes Legales */}
        <div>
          <h3 className="text-xs font-bold text-gray-500">Representantes Legales</h3>
          <div className="space-y-2">
            {selectedBussines?.representantesLegales?.map((representante, index) => (
              <div key={index}>
                <p className="text-xs text-fira-light font-medium text-teal-500">{representante}</p>
                <div className="h-0.5 bg-gray-300 mt-1"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Rut */}
        <div>
          <h3 className="text-xs font-bold text-gray-500">Rut</h3>
          <div className="space-y-2">
            {selectedBussines?.ruts?.map((rut, index) => (
              <div key={index}>
                <p className="text-xs text-fira-light font-medium text-gray-500">{rut}</p>
                <div className="h-0.5 bg-gray-300 mt-1"></div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Fila 4 - Socios */}
      <div className="grid grid-cols-2 gap-6 mt-4 items-start">
        {/* Socios */}
        <div>
          <h3 className="text-xs font-bold text-gray-500">Socios</h3>
          <div className="space-y-2">
            {selectedBussines?.socios?.map((socio, index) => (
              <div key={index}>
                <p className="text-xs text-fira-light font-medium text-teal-500">{socio}</p>
                <div className="h-0.5 bg-gray-300 mt-1"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Rut */}
        <div>
          <h3 className="text-xs font-bold text-gray-500">Rut</h3>
          <div className="space-y-2">
            {selectedBussines?.socios?.map((socio, index) => (
              <div key={index}>
                <p className="text-xs text-fira-light font-medium text-gray-500">{socio.rut}</p>
                <div className="h-0.5 bg-gray-300 mt-1"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatosLegalesEmpresa;