import { Input as BaseInput } from '@mui/base/Input'
import clsx from 'clsx' // utility for constructing className strings conditionally

const Input = ({
  containerStyle,
  name,
  id,
  labelStyle,
  uppercase,
  label,
  className,
  rootClassName,
  ...restProps
}) => (
  <div className={containerStyle}>
    <label htmlFor={name || id}>
      <p
        // potentially harmful
        className={clsx({
          [labelStyle]: labelStyle,
          'text-lg font-bold px-4': !labelStyle,
          uppercase: uppercase
        })}
      >
        {label}
      </p>
    </label>

    <BaseInput
      name={name}
      id={id || name}
      slotProps={{
        root: {
          className: clsx('my-2 flex items-center bg-gray-100 rounded-full', rootClassName)
        },
        input: {
          className: clsx('w-full border-[0px] bg-gray-100 py-2 px-4 focus:ring-0 rounded-full', className)
        }
      }}
      {...restProps}
    />
  </div>
)

export default Input
