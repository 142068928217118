import { FormikHelpers } from 'formik'
import { useLucaSignUp } from '../hooks/useLucaSignUp'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { auth, } from '../../../../../firebase'
import { useMutationCreateUser } from '../queries/userQueries'
import { useLucaAuth } from '../hooks/useAuth'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { useSwiper } from 'swiper/react'
import { UserAuth } from '../../../../../context/AuthContext'
import { BaseFormCreateUser, Step1Values } from '../../components/BaseFormCreateUser'


export const FormCreateUserAccount: FC = () => {
  const addAccountProfileValues = useLucaSignUp(state => state.setAccountProfile)
  const accountProfile = useLucaSignUp(state => state.accountProfile)
  const userMutation = useMutationCreateUser()
  const setApiToken = useLucaAuth(state => state.setApiToken)
  const deleteApiToken = useLucaAuth(state => state.deleteApiToken)
  const [isLoading, setIsLoading] = useState(false)
  const swiper = useSwiper()
  const { user } = UserAuth()
  useEffect(() => {
    if (user) swiper.slideNext()
  }, [user])

  const step1InitialValues: Step1Values = {
    firstname: accountProfile?.firstname || '',
    lastname: accountProfile?.lastname || '',
    phoneNumber: accountProfile?.phoneNumber || '+569',
    email: accountProfile?.email || '',
    password: '',
    rePassword: ''
  }

  const handleSlideNextStep = async (values: Step1Values, actions: FormikHelpers<Step1Values>) => {
    setIsLoading(true)
    const formValues = {
      ...values,
      phoneNumber: values.phoneNumber?.startsWith('+') ?
        values.phoneNumber.slice(1) : values.phoneNumber
    }
    try {
      await toast.promise(handleCreateUserAccount(formValues), {
        pending: {
          render: () => 'Creando usuario',
          className: 'bg-accent1 text-white',
        },
        success: {
          render() {
            return 'Hemos enviado un correo de verificación 👌'
          },
          onClose(props) {
            toast.success('Revisa tu correo para completar el registro')
          },
          className: 'bg-accent1 text-white',
        },
        error: {
          render(error) {
            const myError = error.data as AxiosError<{ message: string }>;
            if (myError.response?.status) {
              return myError.response?.data.message;
            }
            return 'No se pudo crear el usario 🤯';
          },
          className: 'bg-accent1 text-white',
        }
      })
      addAccountProfileValues(formValues)
      actions.setSubmitting(true)
    }
    catch {
      actions.setFieldError('email', 'Correo ya existe')
      deleteApiToken()
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleCreateUserAccount = async (values: Step1Values) => {
    const userCredential = await createUserWithEmailAndPassword(auth, values?.email, values.password)
    const firebaseToken = await userCredential.user.getIdToken()
    const userPayload = {
      token: firebaseToken,
      firstname: values.firstname,
      lastname: values.lastname,
      phoneNumber: values.phoneNumber,
      email: values.email
    }
    // Send email verification
    await sendEmailVerification(userCredential.user);
    const userData = await userMutation.mutateAsync(userPayload)
    setApiToken(userData.token)
    localStorage.setItem('backendToken', userData.token)
    swiper.slideNext()
  }

  return (
    <BaseFormCreateUser
      title='Crea una cuenta en Luca'
      initialValues={step1InitialValues}
      onSubmit={handleSlideNextStep}
      isLoading={isLoading}
    />
  )
}
