import React, { useMemo, useState } from 'react'
import CustomButton from '../../../../ui/CustomButton'
import { Paper } from '../../../../ui/index'
import MonthCard from './MonthCard'
import { useGlobalContext } from '../../../../../../context/GlobalState'
import FilterControl from '../../../../table/FilterControl'

interface Month {
  name: string
  year: string
  number: string
}

const months: Month[] = [
  { name: 'ENERO', year: '2023', number: '01' },
  { name: 'FEBRERO', year: '2023', number: '02' },
  { name: 'MARZO', year: '2023', number: '03' },
  { name: 'ABRIL', year: '2023', number: '04' },
  { name: 'MAYO', year: '2023', number: '05' },
  { name: 'JUNIO', year: '2023', number: '06' },
  { name: 'JULIO', year: '2023', number: '07' },
  { name: 'AGOSTO', year: '2023', number: '08' },
  { name: 'SEPTIEMBRE', year: '2023', number: '09' },
  { name: 'OCTUBRE', year: '2023', number: '10' },
  { name: 'NOVIEMBRE', year: '2023', number: '11' },
  { name: 'DICIEMBRE', year: '2023', number: '12' },
  { name: 'ENERO', year: '2024', number: '01' },
  { name: 'FEBRERO', year: '2024', number: '02' },
  { name: 'MARZO', year: '2024', number: '03' },
  { name: 'ABRIL', year: '2024', number: '04' },
  { name: 'MAYO', year: '2024', number: '05' },
  { name: 'JUNIO', year: '2024', number: '06' },
  { name: 'JULIO', year: '2024', number: '07' },
  { name: 'AGOSTO', year: '2024', number: '08' },
  { name: 'SEPTIEMBRE', year: '2024', number: '09' },
  { name: 'OCTUBRE', year: '2024', number: '10' },
  { name: 'NOVIEMBRE', year: '2024', number: '11' },
  { name: 'DICIEMBRE', year: '2024', number: '12' }
]

const BloqueDePeriodos: React.FC = () => {
  const { ui } = useGlobalContext()
  const { blockedPeriods, setBlockedPeriods } = ui

  const [selectedYear, setSelectedYear] = useState<string | ''>('') // Estado para año seleccionado

  // Obtener el año máximo disponible
  const maxYear = useMemo(() => Math.max(...months.map(month => parseInt(month.year, 10))), [])

  // Actualizar automáticamente el año seleccionado al máximo año
  useMemo(() => {
    setSelectedYear(maxYear.toString())
  }, [maxYear])

  // Verificar si un periodo está bloqueado
  const isBlocked = (year: string, month: string) => {
    const periodo = `${year}-${month}`
    const found = blockedPeriods.find(p => p.periodo === periodo)
    return found ? found.estado : false
  }

  // Determinar si todos los periodos están bloqueados
  const allBlocked = useMemo(() => blockedPeriods.every(p => p.estado), [blockedPeriods])

  // Alternar el bloqueo de un mes específico
  const toggleBlock = (year: string, month: string) => {
    const periodo = `${year}-${month}`
    setBlockedPeriods(prev => {
      const existing = prev.find(p => p.periodo === periodo)
      if (existing) {
        // Si ya existe, alternar su estado
        return prev.map(p => (p.periodo === periodo ? { ...p, estado: !p.estado } : p))
      }
      // Si no existe, agregarlo con estado bloqueado
      return [...prev, { periodo, estado: true }]
    })
  }

  // Alternar el bloqueo de todos los periodos
  const toggleAll = () => {
    const newState = !allBlocked
    const updatedPeriods = months.map(month => ({
      periodo: `${month.year}-${month.number}`,
      estado: newState
    }))
    setBlockedPeriods(updatedPeriods)
  }

  // Extraer años únicos
  const uniqueYears = useMemo(() => Array.from(new Set(months.map(month => month.year))), [])

  // Filtrar meses según el año seleccionado
  const filteredMonths = useMemo(
    () => (selectedYear ? months.filter(month => month.year === selectedYear) : months),
    [selectedYear]
  )

  return (
    <Paper className='p-4 bg-white shadow-md rounded'>
      <div className='relative'>
        <div className='absolute top-[-60px] right-0 z-20 flex space-x-4 pt-2'>
          <FilterControl
            label='Año'
            value={selectedYear}
            onChange={value => setSelectedYear(value)}
            options={[
              { value: '', label: 'Todos' },
              ...uniqueYears.map(year => ({ value: year, label: year }))
            ]}
          />
          <CustomButton
            className='rounded-full whitespace-nowrap'
            color='primary'
            title={allBlocked ? 'DESBLOQUEAR TODO' : 'BLOQUEAR TODO'}
            onClick={toggleAll}
            imageSrc=''
            disabled={false}
            imgStyle={{}}
          />
        </div>
      </div>
      <div className='grid grid-cols-6 gap-4'>
        {filteredMonths.map(month => (
          <MonthCard
            key={`${month.year}-${month.number}`}
            month={month}
            isBlocked={isBlocked(month.year, month.number)}
            onClick={() => toggleBlock(month.year, month.number)}
          />
        ))}
      </div>
    </Paper>
  )
}

export default BloqueDePeriodos
