import { Stack, InputBase } from '@mui/material';
import { styled } from '@mui/system';

// Estilizamos el InputBase para que coincida con el estilo del Select
const StyledInput = styled(InputBase)(({ theme }) => ({
  height: '30px',
  minWidth: '150px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.grey[400]}`,
  padding: '0 12px',
  backgroundColor: theme.palette.background.default,
  fontSize: '0.875rem',
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: 0,
    // Aseguramos que el texto se alinee correctamente y no sugiera que es un selector
  },
  '&:hover': {
    borderColor: theme.palette.grey[500],
  },
  '&:focus-within': {
    borderColor: theme.palette.primary.main,
  },
}));

const FilterInput = ({ label, value, onChange }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <StyledInput
        placeholder={label}  // Usamos el label como placeholder
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputProps={{
          'aria-label': label, // Para accesibilidad
        }}
      />
    </Stack>
  );
};

export default FilterInput;
