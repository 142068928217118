
import { useState, useEffect } from 'react';
import { atributosColaborador } from "../../../../../src/components/modules/data/dataAjustesColaborador";
import CustomButton from "../../../../../src/components/modules/ui/CustomButton.js";

const ModalAtributosColaborador = ({ isModalOpen, setIsModalOpen }) => {
  const [businessInsightChecked, setBusinessInsightChecked] = useState(false);
  const [administracionChecked, setAdministracionChecked] = useState(false);
  const [reportesChecked, setReportesChecked] = useState(false);
  const [miContadorChecked, setMiContadorChecked] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (setter, checked) => {
    setter(checked);
  };

  return (
    isModalOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white w-[600px] p-6 rounded-lg shadow-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Atributos Colaborador</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              &times;
            </button>
          </div>

          <div className="grid grid-cols-4 gap-4 mb-6">
            <div className="ml-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="business insight"
                  className="appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none"
                  checked={businessInsightChecked}
                  onChange={(e) => handleCheckboxChange(setBusinessInsightChecked, e.target.checked)}
                />
                <span className="text-sm ml-2">BusinessInsight</span>
              </label>
              <AtributosCheckbox isChecked={businessInsightChecked} />
            </div>

            <div className="ml-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="administracion"
                  className="appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none"
                  checked={administracionChecked}
                  onChange={(e) => handleCheckboxChange(setAdministracionChecked, e.target.checked)}
                />
                <span className="text-sm ml-2">Administracion</span>
              </label>
              <AtributosCheckbox isChecked={administracionChecked} />
            </div>

            <div className="ml-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="reportes"
                  className="appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none"
                  checked={reportesChecked}
                  onChange={(e) => handleCheckboxChange(setReportesChecked, e.target.checked)}
                />
                <span className="text-sm ml-2">Reportes</span>
              </label>
              <AtributosCheckbox isChecked={reportesChecked} />
            </div>

            <div className="ml-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="mi contador"
                  className="appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none"
                  checked={miContadorChecked}
                  onChange={(e) => handleCheckboxChange(setMiContadorChecked, e.target.checked)}
                />
                <span className="text-sm ml-2">Mi contador</span>
              </label>
              <AtributosCheckbox isChecked={miContadorChecked} />
            </div>
          </div>

          <div className="flex justify-center">
            <CustomButton
              title="Omitir"
              color='light-gray'
              className="mr-2 rounded-full"
              onClick={handleClose}
            />
            <CustomButton
              title="Confirmar"
              color="accent2"
              className="rounded-full"
              onClick={() => console.log('Confirmar cambios')}
            />
          </div>
        </div>
      </div>
    )
  );
};

const AtributosCheckbox = ({ isChecked }) => {
  const [secondaryCheckboxes, setSecondaryCheckboxes] = useState(
    atributosColaborador.map((item) => ({ ...item, checked: isChecked }))
  );

  const handleSecondaryCheckboxChange = (index) => {
    setSecondaryCheckboxes((prevState) =>
      prevState.map((checkbox, i) =>
        i === index ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  useEffect(() => {
    setSecondaryCheckboxes((prevState) =>
      prevState.map((checkbox) => ({ ...checkbox, checked: isChecked }))
    );
  }, [isChecked]);

  return (
    <div className="mt-2 ml-2"> {/* Ajuste para los checkboxes secundarios */}
      {secondaryCheckboxes.map((item, index) => (
        <label key={index} className="block text-xs">
          <input
            type="checkbox"
            className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none"
            checked={item.checked}
            onChange={() => handleSecondaryCheckboxChange(index)}
          />
          {item.nombre}
        </label>
      ))}
    </div>
  );
};

export default ModalAtributosColaborador;