import { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../context/global-context'
import { toast } from 'react-toastify'

// MUI
import { Backdrop } from '@mui/material'

//api
import { BusinessAPI } from '../../../api/business.api'

// components
import { HeadingTitle, Paper, Text, Select } from '../../../components/modules/ui/index'
import Table from './Table'
import { ExportCSV, ExportXLS } from '../../../components/modules/table/ExportData'
import ExportPDF from '../../../components/modules/table/typesExportPDF/ExportPDFReportesLibroMayor'
import { formatCurrency, formatDate } from '../../../components/utils/formatter'
import {
  currentMonth,
  currentYear,
  monthsOptions as months,
} from '../../../components/utils/constant'
import CustomButton from '../../../components/modules/ui/CustomButton'

const ListLibroMayor = () => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [dateFrom, setDateFrom] = useState({ month: currentMonth, year: currentYear })
  const [dateTo, setDateTo] = useState({ month: currentMonth, year: currentYear })
  const [monthsOptionsTo, setMonthsOptionsTo] = useState(months)
  const [acount, setAcount] = useState([])
  const [currentAcount, setCurrentAcount] = useState({})
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const businessAPI = new BusinessAPI()
  const [filterText, setFilterText] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  const handleFilterChange = (e) => {
    setFilterText(e.target.value)
    setShowOptions(true)
  }

  const handleOptionClick = (selectedAcount) => {
    setCurrentAcount(selectedAcount.id)
    setFilterText(`${selectedAcount.codigoCuenta} - ${selectedAcount.nombreCuenta}`)
    setShowOptions(false)
  }

  const filteredAcounts = acount.filter((unAcount) =>
    unAcount.codigoCuenta.toLowerCase().includes(filterText.toLowerCase()) ||
    unAcount.nombreCuenta.toLowerCase().includes(filterText.toLowerCase())
  )

  const getDataAcounts = async () => {
    if (currentBusiness.id) {
      await businessAPI
        .getCuentasUsadas({
          businessId: currentBusiness.id
        })
        .then(({ data }) => {
          const sortedData = data.sort((a, b) => a.nombreCuenta.localeCompare(b.nombreCuenta))
          setAcount(sortedData)
        })
        .catch(error => {
          setAcount([])
          console.error(error)
        })
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  const toastMessageBank = async () => {
    await toast.promise(getDataAcounts(), {
      pending: {
        render: () => 'Cargando cuentas... ',
        className: 'bg-accent2 text-white'
      },
      success: {
        render: () => 'Cuentas cargadas exitosamente',
        className: 'bg-accent2 text-white'
      },
      error: {
        render: () => 'No se pudieron cargar las cuentas',
        className: 'bg-accent1 text-white',
      }
    })
  }

  const getData = async () => {
    // const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      ui.setLoader({ visible: true, text: 'Cargando cuentas' })
      await businessAPI
        .getDataLibroMayor({
          businessId: currentBusiness.id,
          idCuenta: currentAcount,
          dateFrom: dateFrom,
          dateTo: dateTo
        })
        .then(({ data }) => {
          setData(data)
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No hay datos')
    }
  }

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const dataToExport = data.map(item => {
    return {
      Fecha: item.fecha && formatDate(item.fecha),
      'Tipo de comprobante': item.tipoComprobante.nombreTipoComprobante,
      Comprobante: item.idOrigin,
      Rut: item.informacion.rutEmpresa,
      'Razon Social': item.informacion.razonSocial,
      Documento: item.numero,
      Debe: formatCurrency(item.debe),
      Haber: formatCurrency(item.haber),
      saldo: item.saldo,
      Glosa: item.glosa
    }
  })

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro mayor'
      month={`desde_${dateFrom.month}_${dateFrom.year}`}
      year={`hasta_${dateTo.month}_${dateTo.year}`}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro mayor'
      month={`desde_${dateFrom.month}_${dateFrom.year}`}
      year={`hasta_${dateTo.month}_${dateTo.year}`}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      setShowModal={setShowModal}
      title='Reportes libro mayor'
      dateFrom={dateFrom}
      dateTo={dateTo}
    />
  ]

  /*const monthsOptionsFrom = months.map(monthOption => ({
    ...monthOption,
    disabled: dateFrom.year == currentYear && monthOption.value > currentMonth
  }))*/

  useEffect(() => {
    const monthsOptions = months.map(monthOption => ({
      ...monthOption,
      disabled: dateFrom.month > monthOption.value
    }))
    setMonthsOptionsTo(monthsOptions)
  }, [dateFrom.month])

  useEffect(() => {
    toastMessageBank()
  }, [])

const handleDateFromChange = (e) => {
  console.log(monthsOptionsTo)
  const [year, month] = e.target.value.split('-').map(Number)
  setDateFrom({ month, year })
  
  if (year === dateTo.year && month >= dateTo.month) {
    setDateTo({ month: month + 1, year })
  }
}

const handleDateToChange = (e) => {
  const [year, month] = e.target.value.split('-').map(Number)
  setDateTo({ month, year })
}

  return (
    <>
      <Paper>
        <HeadingTitle title='Libro Mayor' rightIcons={icons} iconHeight={32} />
        <div>
          <div className='flex flex-wrap gap-2 items-end'>
            <div className='flex flex-col'>
              <label>Desde</label>
              <input
                type='month'
                value={`${dateFrom.year}-${String(dateFrom.month).padStart(2, '0')}`}
                onChange={handleDateFromChange}
                min='2024-01'
                max='2024-12'
                className='w-full border border-gray-300 rounded-full px-2 py-1 text-sm'
              />
            </div>

            <div className='flex flex-col'>
              <label>Hasta</label>
              <input
                type='month'
                value={`${dateTo.year}-${String(dateTo.month).padStart(2, '0')}`}
                onChange={handleDateToChange}
                min={`2024-${String(dateFrom.month + 1).padStart(2, '0')}`}
                max='2024-12'
                className='w-full border border-gray-300 rounded-full px-2 py-1 text-sm'
              />
            </div>

            <div className='relative flex flex-col'>
              <label>Cuenta</label>
              <input
                type='text'
                placeholder='Buscar por código o nombre'
                value={filterText}
                onChange={handleFilterChange}
                onFocus={() => setShowOptions(true)}
                className='w-full border border-gray-300 rounded-full px-2 py-1 text-sm'
              />
              {showOptions && filteredAcounts.length > 0 && (
                <ul className='absolute top-full left-0 w-full max-h-40 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg z-10'>
                  {filteredAcounts.map((unAcount) => (
                    <li
                      key={unAcount.id}
                      onClick={() => handleOptionClick(unAcount)}
                      className='px-4 py-2 cursor-pointer hover:bg-gray-200'
                    >
                      {unAcount.codigoCuenta} - {unAcount.nombreCuenta}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className='flex flex-col'>
              <label className='text-sm mb-1'>Centro de Costo</label>
              <Select label='Centro de Costo' value={''} options={[]} disabled />
            </div>

            <div className='flex flex-col'>
              <label className='text-sm mb-1'>Sucursal</label>
              <Select label='Sucursal' value={''} options={[]} disabled />
            </div>

            <CustomButton
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={getData}
            />
          </div>
        </div>
        <Table rows={data} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}


export default ListLibroMayor
