import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CustomButton from '../ui/CustomButton'

const CustomPagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const handlePageChange = (e, newPage) => {
    newPage = newPage < 0 ? 0 : newPage
    e.preventDefault()
    onPageChange(newPage)
  }

  const totalPages = rowsPerPage === -1 ? 1 : Math.ceil(count / rowsPerPage);
  const currentPage = page + 1

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' marginTop={4}>
      <Typography variant='body2'>
        {rowsPerPage === -1
          ? `1 - ${count} de ${count}` 
          : `${currentPage * rowsPerPage - rowsPerPage + 1} - ${Math.min(count, currentPage * rowsPerPage)} de ${count}`}
      </Typography>
      <Stack direction='row' spacing={2}>
        <CustomButton
          onClick={e => handlePageChange(e, page - 1)}
          disabled={page === 0 || rowsPerPage === -1} 
          color='accent1'
          title='Anterior'
          className='rounded-full'
        />
        <CustomButton
          onClick={e => handlePageChange(e, page + 1)}
          disabled={page === totalPages - 1 || rowsPerPage === -1}
          variant='contained'
          color='accent1'
          title='Siguiente'
          className='rounded-full'
        />
      </Stack>
    </Stack>
  )
}

export default CustomPagination
export { CustomPagination }
