import { useState } from "react";
import { Folder, FolderOpen } from "@mui/icons-material";
import iconLapiz from "../../../../../../../assets/iconos/iconLapiz.png";
import translations from "../../../../../ui/traslation/translationPLanDeCuentas.json";

/**
 * Estructura las carpetas en jerarquías basadas en `codigoCuenta`.
 * @param {Array} data - Lista de elementos del plan de cuentas.
 * @returns {Array} Lista jerarquizada de carpetas.
 */
export const structureFolders = (data) => {
  const folderMap = new Map();

  // Crear mapa inicial de carpetas
  data.forEach((item) => {
    folderMap.set(item.codigoCuenta, { ...item, subfolders: [] });
  });

  const rootFolders = [];

  // Construir la jerarquía
  data.forEach((item) => {
    const level = item.codigoCuenta.split("-").filter((num) => num !== "0").length;
    const parentCode = getParentCode(item.codigoCuenta, level);

    if (parentCode) {
      const parentFolder = folderMap.get(parentCode);
      if (parentFolder) {
        parentFolder.subfolders.push(folderMap.get(item.codigoCuenta));
      } else {
        rootFolders.push(folderMap.get(item.codigoCuenta));
      }
    } else {
      rootFolders.push(folderMap.get(item.codigoCuenta));
    }
  });

  return rootFolders;
};

/**
 * Obtiene el código del padre basado en el nivel.
 * @param {string} codigoCuenta - Código actual de la cuenta.
 * @param {number} level - Nivel del código actual.
 * @returns {string|null} Código del padre, o null si no existe.
 */
export const getParentCode = (codigoCuenta, level) => {
  const parts = codigoCuenta.split("-");
  if (level === 2) return `${parts[0]}-0-0-0`;
  if (level === 3) return `${parts[0]}-${parts[1]}-0-0`;
  if (level === 4) return `${parts[0]}-${parts[1]}-${parts[2]}-0`;
  return null;
};

const FolderItem = ({ folder, level = 1 }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Cambiar estado solo si no es el nivel 4
  const toggleFolder = () => {
    if (level < 4) {
      setIsOpen((prevState) => !prevState);
    }
  };

  return (
    <div style={{ marginLeft: `${level * 16}px`, marginTop: "8px" }}>
      <FolderHeader
        folderName={folder.nombreCuenta}
        codigoCuenta={folder.codigoCuenta}
        isOpen={isOpen}
        toggleFolder={toggleFolder}
        level={level}
      />
      {isOpen && level < 4 && folder.subfolders?.length > 0 && (
        <FolderList subfolders={folder.subfolders} level={level + 1} />
      )}
    </div>
  );
};

const FolderHeader = ({ folderName, codigoCuenta, isOpen, toggleFolder, level }) => {
  return (
    <div
      className={`flex items-center justify-between cursor-pointer ${level === 4 ? "cursor-default" : ""}`} // Desactivar cursor en nivel 4
      onClick={toggleFolder}
    >
      <div className="flex items-center">
        {/* Mostrar siempre Folder cerrado si es nivel 4 */}
        {level === 4 ? (
          <Folder className="text-gray-600" title={translations.folderStructure.icons.closedFolder} />
        ) : isOpen ? (
          <FolderOpen className="text-gray-600" title={translations.folderStructure.icons.openFolder} />
        ) : (
          <Folder className="text-gray-600" title={translations.folderStructure.icons.closedFolder} />
        )}
        {/* Mostrar el codigoCuenta antes del nombre */}
        <span className="ml-2 text-sm font-medium text-gray-800">
          {codigoCuenta} {folderName}
        </span>
      </div>
      {/* Mostrar el botón solo si el nivel es 3 o 4 */}
      {level >= 3 && <EditButton />}
    </div>
  );
};

const EditButton = () => (
  <button
    className="transform hover:scale-110 hover:opacity-80 hover:shadow-xl transition-all duration-200"
    title={translations.folderStructure.editButton} // Agregar tooltip con traducción
  >
    <img src={iconLapiz} className="w-6 h-6" alt={translations.folderStructure.editButton} />
  </button>
);

const FolderList = ({ subfolders, level }) => {
  return (
    <div>
      {subfolders.map((subfolder) => (
        <FolderItem key={subfolder.id} folder={subfolder} level={level} />
      ))}
    </div>
  );
};

export default FolderItem;