import { AxiosInstance } from 'axios';
import { PlanCuentas } from '../models/planCuentas'; // Asegúrate de que el archivo `planCuentas.ts` existe
import { BaseHTTP } from './base.http'

export class PlanCuentasApi {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  getPlanCuentaById() {
    const path = '/v1/cuenta-contable/'; // Ruta para obtener el detalle de una cuenta específica
    return this.http.get<PlanCuentas>(path); // Devuelve la respuesta tipada
  }
}