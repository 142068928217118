import React from 'react'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'

interface Month {
  name: string
  year: string
  number: string
}

interface MonthCardProps {
  month: Month
  isBlocked: boolean
  onClick: () => void
}

const MonthCard: React.FC<MonthCardProps> = ({ month, isBlocked, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`border rounded-md flex flex-col items-center justify-center p-4 relative h-48 cursor-pointer 
        ${isBlocked ? 'bg-gray-500 text-white' : 'bg-white text-black'}`}
    >
      <div className={`absolute text-7xl font-bold top-0 right-2 
        ${isBlocked ? 'text-gray-300' : 'text-gray-200'}`}>
        {month.number}
      </div>
      <div className="text-center z-10">
        <h3 className="font-bold text-lg">{month.name}</h3>
        <p className="text-sm">{month.year}</p>
      </div>
      {isBlocked ? (
        <LockIcon className="mt-2 text-white" />
      ) : (
        <LockOpenIcon className="mt-2 text-gray-500" />
      )}
    </div>
  )
}

export default MonthCard
