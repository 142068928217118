import { useState } from 'react'

//components
import Empresa from './Empresa'
import AtributosCaracteristicas from './AtributosCaracteristicas'
import Contribuyentes from './Contribuyente'
import DatosLegalesEmpresa from './Legales'

//ui
import { Paper } from '../../../../../ui/index'

//mui
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

const Index = ({ selectedBussines }) => {
  const [value, setValue] = useState(0)
  console.log(selectedBussines)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  return (
    <Paper className='shadow-full-xs rounded-3xl bg-secondary'>
      <Box className='font-fira'>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              fontWeight: 'bold',
              color: 'text.primary',
              fontSize: 12, // Equivalente a text-sm en Tailwind
              '& .MuiTabs-indicator': {
                display: 'none' // Eliminar la línea indicadora
              },
              '& .MuiTab-root': {
                textTransform: 'none', // Para asegurar que el texto no se transforme en mayúsculas
                fontFamily: 'Fira Sans, sans-serif',
                fontWeight: 700,
              }
            }}
          >
            <Tab className='font-fira' label='EMPRESA' {...a11yProps(0)} />
            <Tab label='LEGALES' {...a11yProps(1)} />
            <Tab label='CONTRIBUYENTE' {...a11yProps(2)} />
            <Tab label='ATRIBUTOS/CARACTERISTICAS' {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Empresa selectedBussines={selectedBussines} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DatosLegalesEmpresa selectedBussines={selectedBussines}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Contribuyentes selectedBussines={selectedBussines}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <AtributosCaracteristicas selectedBussines={selectedBussines}/>
        </CustomTabPanel>
      </Box>
    </Paper>
  )
}

export default Index
