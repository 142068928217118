import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserAuth } from "../../../../../context/AuthContext";
import { verifyPasswordResetCode, confirmPasswordReset, Auth, } from 'firebase/auth'
import * as Yup from 'yup'
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { auth } from "../../../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FieldWithErrorMessage } from "../../../ui/FieldWithErrorMsg";


interface FormValues {
  password: string;
  rePassword: string;
}

export const validationSchema = Yup.object<FormValues>({
  password: Yup.string()
    .required('Ingresa tu contraseña')
    .test('minLength', 'Debe tener al menos 8 caracteres', value => value.length >= 8)
    .test('oneUppercase', 'Debe incluir al menos una letra mayúscula', value => /[A-Z]/.test(value))
    .test('oneLowercase', 'Debe incluir al menos una letra minúscula', value =>
      /[a-z]/.test(value)
    ),
  rePassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Las contraseñas no coinciden')
    .required('Repite tu contraseña')
})


export const CompleteResetPassword: FC = () => {
  const navigate = useNavigate()
  const { user, isAuthenticated } = UserAuth()
  const [urlParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [hideButton, setHideButton] = useState(false)

  const handleResetPassword = (auth: Auth, actionCode: string, newPassword: string) => {
    setIsLoading(true)
    verifyPasswordResetCode(auth, actionCode)
      .then(() => {
        setHideButton(true)
        confirmPasswordReset(auth, actionCode, newPassword)
          .then(() => {
            toast.success('Contraseña reestablecida exitosamente', {
              onClose: () => {
                toast.success('Ahora ingresa tus nuevas credenciales')
                navigate('/auth/signin', { replace: true })
              },
              className: 'bg-accent2 text-white',
            })
          })
          .catch(() => {
            toast.error('No se pudo reestablecer tu contraseña', {
              onClose: () => {
                navigate('/auth/reset-password', { replace: true })
              },
              className: 'bg-accent1 text-white',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
      .catch((error) => {
        let errorMessage = 'El link ha expirado'
        if (error.code !== 'auth/expired-action-code') {
          errorMessage = error.message
        }
        toast.error(errorMessage, {
          onClose: () => {
            navigate('/auth/reset-password', { replace: true })
          },
          className: 'bg-accent1 text-white',
        })
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      navigate('/', { replace: true })
    }
  }, [user, isAuthenticated])

  return (
    <div
      className='relative px-7 py-9 w-[570px] h-min[460px] bg-white rounded-lg shadow-md mb-12'
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          password: '',
          rePassword: ''
        }}
        onSubmit={(values, actions) => {
          const obbcode = urlParams.get('oobCode') ?? ''
          handleResetPassword(auth, obbcode, values.password)
          actions.resetForm()
          actions.setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form className='px-1'>
            <ul className='grid grid-cols-2 gap-x-8 gap-y-3'>
              <li className="col-span-2">
                <h1 className='text-3xl font-bold mb-4 text-center'>Reestablecer contraseña</h1>
              </li>
              <li>
                <FieldWithErrorMessage
                  tooltip={true}
                  name='password'
                  type='password'
                  placeholder='Ingresa tu contraseña'
                  label='Contraseña'
                />
              </li>

              <li>
                <FieldWithErrorMessage
                  name='rePassword'
                  type='password'
                  placeholder='Repite tu contraseña'
                  label='Confirmar contraseña'
                />
              </li>
            </ul>

            <div className='flex flex-col gap-2 mt-6 w-2/3 mx-auto'>
              <div>
                <button
                  disabled={hideButton || isSubmitting}
                  type='submit'
                  className={`btn-primary rounded-full ${hideButton ? 'opacity-60' : ''}`}
                >
                  {isLoading ? (
                    <span>Restableciendo...</span>
                  ) : (
                    <>
                      <span>Continuar</span>
                      <FontAwesomeIcon size='lg' icon={faArrowRight} />
                    </>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
