import { useState, useEffect } from 'react'

const useSidebar = () => {
  const [isExpanded, setIsExpanded] = useState(() => {
    const storedIsExpanded = localStorage.getItem('isExpanded')
    return storedIsExpanded ? storedIsExpanded === 'true' : false
  })

  const [isPinned, setIsPinned] = useState(() => localStorage.getItem('isPinned') === 'true')

  const toggleSidebar = () => {
    if (!isPinned) {
      setIsExpanded((prev) => !prev)
    }
  }

  const togglePinSidebar = () => {
    const newIsPinned = !isPinned
    setIsPinned(newIsPinned)
    localStorage.setItem('isPinned', newIsPinned)
    if (newIsPinned) {
      setIsExpanded(true)
    }
  }

  useEffect(() => {
    localStorage.setItem('isExpanded', isExpanded)
  }, [isExpanded])

  return {
    isExpanded,
    isPinned,
    toggleSidebar,
    togglePinSidebar,
  }
}

export default useSidebar
