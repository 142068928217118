import { FC, useState, useEffect } from 'react'
// components
import AutoCompleteHeader from '../../modules/AutoCompleteHeader'
import Notification from './Notification'
import { Settings } from './settings/Settings'
import { Modal } from '../../modules/ui/Modal'
import { Skeleton } from '@mui/material'
// assets
import iconParlanteNegro from '../../../assets/iconos/iconParlanteNegro.png'
import iconTuercaNegro from '../../../assets/iconos/iconTuercaNegro.png'
import { useGlobalContext } from '../../../context/GlobalState'
import { IUserMe } from '../../../models/User'
import { IBusiness } from '../../../models/Business'
import { useQueryGetUserMe } from '../../../queries/userQueries'
import { OrganizationAPI } from '../../../api/organization.api'

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export const Header = () => {
  const { business, currentBusiness, setCurrentBusiness } = useGlobalContext()
  const { data: userMe } = useQueryGetUserMe()
  const [isOpen, setIsOpen] = useState(false)
  const [typeModal, setTypeModal] = useState('')
  const [hasNotifications, setHasNotifications] = useState(false)
  const isLoading = false

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const organizationAPI = new OrganizationAPI()
        const response = await organizationAPI.getNotification({ businessId: currentBusiness?.id })
  
        if (Array.isArray(response.data) && response.data.length > 0) {
          setHasNotifications(true)
        } else {
          setHasNotifications(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
  
    if (currentBusiness?.id) {
      fetchNotification()
    }
  }, [currentBusiness?.id])

  const selectNotification = type => {
    if (type === 'notification') {
      setIsOpen(true)
      setTypeModal('notification')
    } else {
      setTypeModal('')
      setIsOpen(true)
    }
  }

  const NavbarBarWithSearchInput = () => (
    <div className='fixed w-[calc(100%-75rem)] right-0 fixed z-20'>
      {isLoading ? (
        <Skeleton variant='rectangular' width={'100%'} height={80} />
      ) : (
        <nav className='h-20 flex justify-end items-center px-14 bg-slate-50/75'>
          <div className='flex gap-9 items-center justify-end'>
            <AutoCompleteHeader
              businessData={business ?? []}
              currentBusiness={currentBusiness ?? ''}
              onClick={setCurrentBusiness}
            />

            <section className='flex items-center gap-5'>
              <button
                onClick={() => selectNotification('notification')}
                type='button'
                className='p-2 w-11 h-11 transition-transform duration-200 ease-in-out hover:scale-110 relative'
              >
                <img src={iconParlanteNegro} alt='sound-icon' />
                {hasNotifications && (
                  <span className='absolute top-0 right-0 w-4 h-4 bg-red-500 text-white rounded-full text-xs flex items-center justify-center'>
                    !
                  </span>
                )}
              </button>

              <button
                onClick={() => selectNotification('')}
                type='button'
                className='p-2 w-11 h-11 transition-transform duration-200 ease-in-out hover:scale-110'
              >
                <img src={iconTuercaNegro} alt='gear-icon' />
              </button>
            </section>
          </div>
        </nav>
      )}
    </div>
  )

  return (
    <header>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <>
          {typeModal === 'notification' ? (
            <Notification onClose={() => setIsOpen(false)} setHasNotifications={setHasNotifications} />
          ) : (
            <Settings onClose={() => setIsOpen(false)} />
          )}
        </>
      </Modal>

      <NavbarBarWithSearchInput />

      <CardCompanyInfo
        isLoading={isLoading}
        loggedInUser={userMe}
        currentBusiness={currentBusiness}
      />
    </header>
  )
}

interface CompanyInfoProps {
  isLoading: boolean
  loggedInUser?: IUserMe | null,
  currentBusiness?: IBusiness

}

const CardCompanyInfo: FC<CompanyInfoProps> = ({ isLoading, loggedInUser, currentBusiness }) => (
  <div className='pt-28 pb-6 px-14 mx-auto'>
    <section
      style={{
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100
      }}
      className='border-slate-100/60 border shadow-md w-fit flex items-center h-full'
    >
      <div className='justify-between flex flex-col gap-3 pl-7 pr-2 py-2'>
        <div className='mb-1'>
          {isLoading ? (
            <h2 className='font-medium text-[24px] mb-1 w-2/3'>
              <Skeleton variant='text' sx={{ fontSize: 24 }} />
            </h2>
          ) : (
            <h2 className='font-medium text-[24px] mb-1'>
              ¡
              <span className='border-b-2 border-orange-500'>Hola {loggedInUser?.firstname}</span>
              !
            </h2>
          )}
          <p className='text-[15px] mt-2'>Revisa aqui lo que esta pasando con tu negocio</p>
        </div>

        {isLoading ? (
          <div>
            <h1 className='font-bold text-[28px]'>
              <Skeleton variant='text' sx={{ fontSize: 28 }} />
            </h1>
            <h6 className='font-medium text-[15px]'>
              <Skeleton variant='text' sx={{ fontSize: 15 }} />
            </h6>
          </div>
        ) : (
          <div>
            <h1 className='font-bold text-[28px]'>
              {currentBusiness?.nombreFantasia ?? 'Tu Empresa'}
            </h1>
            <h6 className='font-medium text-[15px]'>{currentBusiness?.rut ?? 'rut number'}</h6>
          </div>
        )}
      </div>

      <figure className='m-2 w-[128px] h-[128px] overflow-hidden border-2 border-slate-200/75 rounded-full'>
        <img
          className='w-full h-full object-cover'
          src={currentBusiness?.image?.url ?? 'https://placehold.co/400'}
          alt='company-main-logo'
        />
      </figure>
    </section>
  </div>
)
