//react
import { useEffect, useState, useContext } from 'react'
import GlobalContext from '../../../../../../../context/global-context'
import { toast } from 'react-toastify'

//api
import { BankAPI } from '../../../../../../../api/bancos.api'

//components
import { Button } from '../../../../../ui/index'
import ModalEditAcounts from '../modalDialogEditAcounts/ModalEditAcounts'
import List from './List'
import { getWidgetToken } from '../../bancos/modalConectFintoc/contectModalStep'

//utils
import { formatCurrency } from '../../../../../../utils/formatter'
import { currentMonth, currentYear } from '../../../../../../utils/constant'

//data
import { firstObjectListAcountBank } from '../data'


const BancosTableInsights = ({ setConnectBank }) => {
  const [openModalEditAcounts, setOpenModalEditAcounts] = useState(false);
  const [deudasPagos, setDeudasPagos] = useState({ porPagar: 0, porCobrar: 0 });
  const [acounts, setAcounts] = useState([]);
  const [transactionsAcount, setTransactionsAcount] = useState([]);
  const [currentAcount, setCurrentAcount] = useState(acounts[0] || null);
  const { currentBusiness } = useContext(GlobalContext);

  const bankAPI = new BankAPI()

  // Función para identificar la cuenta y el banco de búsqueda
  const handleItemSelection = (event) => {
    const { value } = event.target;
    const selectedAcount = acounts.find((acount) => acount.id === parseInt(value));
    setCurrentAcount(selectedAcount);
  };

  const fetchData = async () => {
    try {
      const [ listAcountBankResponse] = await Promise.all([
        bankAPI.getListAcountBank(currentBusiness.id)
      ])

      const temporaryListAcountBank = [firstObjectListAcountBank, ...listAcountBankResponse.data]
      getDataListTransactionsAcount(temporaryListAcountBank[0].id)
      setAcounts(temporaryListAcountBank)
    } catch (error) {
      toast.error(
        'No se pudieron cargar los datos 🤯' + JSON.stringify(error.response.data.message)
      )
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    console.log(fetchData)
  }, [])


  const getDataListTransactionsAcount = async (defaultId = null) => {
    try {
      const limit = 100;
      const monthFormatter = currentMonth.toString().padStart(2, '0'); // Formatea el mes con dos dígitos

      // Llama a `getTransactionsSummary` del API
      const transactions = await bankAPI.getTransactionsSummary(
        defaultId || 0, // Si no hay cuenta seleccionada, usa 0 para todas
        currentBusiness.id, // ID del negocio
        limit, // Límite de registros
        currentYear, // Año actual
        parseInt(monthFormatter) // Mes actual formateado como número
      );

      // Actualiza el estado con los datos recibidos
      setTransactionsAcount(transactions);
    } catch (error) {
      console.error('Error al obtener las transacciones:', error);
      setTransactionsAcount([]); // En caso de error, asegura un estado vacío
    }
  };


  useEffect(() => {
    getDataListTransactionsAcount()
  }, [currentBusiness])


  // Función para obtener deudas y pagos
  const fetchDeudasPagos = async () => {
    try {
      const response = await bankAPI.getTotalDeudasPagos(currentBusiness.id);
      setDeudasPagos(response.data);
    } catch (error) {
      toast.error('No se pudieron cargar los datos de deudas y pagos 🤯');
      console.error('Error al obtener deudas y pagos:', error);
    }
  };

  // useEffect para cargar las deudas y pagos al iniciar o cuando cambie el negocio actual
  useEffect(() => {
    if (currentBusiness) {
      fetchDeudasPagos();
    }
  }, [currentBusiness]);

  return (
    <div className="my-10 space-y-6">
      <div className="grid grid-cols-3 gap-6">
        <section className="flex flex-col shadow border border-gray-300 p-4 rounded-2xl">
          <h2 className="text-2xl">Facturas por pagar</h2>
          <p className="text-[#8E9AB1] font-bold">
            {deudasPagos.porPagar ? formatCurrency(deudasPagos.porPagar) : '$ 0'}
          </p>
          <p className="uppercase text-sm font-semibold text-accent6">total</p>
        </section>
        <section className="flex flex-col shadow border border-gray-300 p-4 rounded-2xl">
          <h1 className="text-2xl">Facturas por cobrar</h1>
          <p className="text-[#8E9AB1] font-bold">
            {deudasPagos.porCobrar ? formatCurrency(deudasPagos.porCobrar) : '$ 0'}
          </p>
          <p className="uppercase text-sm font-semibold text-accent6">total</p>
        </section>
        <section className="flex flex-col shadow border border-gray-300 p-4 rounded-2xl">
          <h1 className="text-2xl">Saldo en cuenta</h1>
          <p className="text-[#8E9AB1] font-bold">
            {currentAcount?.saldo ? formatCurrency(currentAcount?.saldo) : '$ 0'}
          </p>
          <p className="uppercase text-sm font-semibold text-accent6">total</p>
        </section>
      </div>

      <div className="grid grid-cols-4 gap-6">
        <select
          className="appearance-none focus:outline-none focus:ring-0 border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-full shadow leading-tight"
          name="acount"
          onChange={handleItemSelection}
          value={currentAcount?.id || ''}
        >
          <option value="" disabled>
            Selecciona Banco
          </option>
          {acounts.map((unAcounts) => (
            <option key={unAcounts.id} value={unAcounts.id}>
              {`${unAcounts.bancoCuenta.bank.name}, ${unAcounts.cuentaTipo.name}, ${unAcounts.numeroCuenta}`}
            </option>
          ))}
        </select>
        {/* <section className="border border-gray-300 rounded-full shadow leading-tight px-4 py-2 pr-8">
          {currentAcount ? (
            <>
              <span>{currentAcount.bancoCuenta.bank.name}, </span>
              <span>{currentAcount.cuentaTipo.name}, </span>
              <span>{currentAcount.numeroCuenta}</span>
            </>
          ) 
          : 
          (
             <span>Todas las cuentas</span>
          )
          }
        </section> */}
        <Button
          onClick={getWidgetToken} // Se corrige el nombre
          className="shadow-5xl border-none px-8 py-3 rounded-2xl uppercase bg-accent2 text-white font-bold text-xs"
          title="Agregar nuevo banco"
        />
        {/* <Button
          onClick={() => setOpenModalEditAcounts(true)}
          className="shadow-5xl border-none px-8 py-3 rounded-2xl uppercase bg-accent6 text-white font-bold text-xs"
          title="Editar cuentas"
        /> */}
      </div>

      <List data={transactionsAcount} currentAcount={currentAcount} />
      {openModalEditAcounts && (
        <ModalEditAcounts
          acounts={acounts}
          open={openModalEditAcounts}
          setOpenModal={setOpenModalEditAcounts}
        />
      )}
    </div>
  );
};

export default BancosTableInsights;