import clsx from 'clsx'
import Divider from './Divider'
import { Fragment } from 'react'

const HeadingTitle = ({ title, subtitle, rightIcons, iconHeight = 24, iconWidth }) => {
  //   const bottom = `bottom-${iconHeight}`
  const width = iconWidth || iconHeight
  return (
    <div className='w-full py-6 mb-6'>
      <h1 className='text-2xl font-light tracking-wide'>{title}</h1>
      {subtitle && <p className='text-lg'>{subtitle}</p>}
      <div className='relative flex items-center'>
        {rightIcons && (
          <div className={clsx('absolute right-0 flex gap-4')}>
            {rightIcons.map((icon, index) => (
              <Fragment key={index}>
                {typeof icon === 'string' ? (
                  <img
                    className='cursor-pointer rounded-full shadow-full-xs bg-white'
                    src={icon}
                    height={iconHeight}
                    width={width}
                  />
                ) : (
                  icon
                )}
              </Fragment>
            ))}
          </div>
        )}
        <Divider />
      </div>
    </div>
  )
}

export default HeadingTitle
