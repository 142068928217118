/* eslint-disable */
import { ReactComponent as AdministrationIcon } from '../../../assets/svgIcons/administrationIcon.svg'
import { ReactComponent as BusinessInsightsIcon } from '../../../assets/svgIcons/businessInsightsIcon.svg'
import { ReactComponent as ReportesIcon } from '../../../assets/svgIcons/reportesIcon.svg'
import { ReactComponent as AjustesIcon } from '../../../assets/svgIcons/ajustesIcon.svg'
import { ReactComponent as ContadorIcon } from '../../../assets/svgIcons/1Recurso17.svg'

export const menuItemsDefaults = [
  {
    id: 1,
    name: 'Business Insights',
    icon: BusinessInsightsIcon,
    path: '/'
  },
  {
    id: 2,
    name: 'Administración',
    icon: AdministrationIcon,
    path: '/administracion'
  },
  {
    id: 3,
    name: 'Reportes',
    icon: ReportesIcon,
    path: '/reportes'
  },
  {
    id: 4,
    name: 'Mi Contador',
    icon: ContadorIcon,
    path: '/micontador'
  },
  {
    id: 5,
    name: 'Ajustes',
    icon: AjustesIcon,
    path: '/ajustes'
  }
]
