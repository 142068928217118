import { Dialog, DialogContent } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

const PlanDeCuentasModal = ({ onClose, isModalOpen, setIsModalOpen }) => {
  const handleClose = () => { setIsModalOpen(false) }

  return (
    <Fragment>
      <Dialog open={isModalOpen} maxWidth='lg' onClose={handleClose}>
        <DialogContent className='bg-gray-100'>
          <div className="rounded-lg p-6 relative">
            <button
              className="absolute top-2 right-2 text-gray-500"
              onClick={onClose}
            >
              ✕
            </button>
            <h3 className="text-lg font-bold mb-2">Agregar Plan de Cuentas</h3>
            <p>Formulario para agregar o editar un plan de cuentas.</p>

            {/* Opciones dentro del modal */}
            <div className="mb-4">
              <p className="text-sm text-gray-700">¿Quieres agregar una nueva cuenta?</p>
              <button
                className="mt-2 px-4 py-2 bg-teal-500 text-white font-semibold rounded"
                onClick={() => alert("Agregar nueva cuenta")}
              >
                Agregar
              </button>
            </div>

            <div>
              <p className="text-sm text-gray-700">¿Quieres editar una cuenta existente?</p>
              <button
                className="mt-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded"
                onClick={() => alert("Editar cuenta existente")}
              >
                Editar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PlanDeCuentasModal;