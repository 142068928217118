import { atributos } from "../../../../../data/dataAjustesEmpresas";

const AtributosCaracteristicas = () => {
    const column1 = atributos.slice(0, 10); // Primeros 10 ítems
    const column2 = atributos.slice(10, 20); // Siguientes 10 ítems

    return (
        
      <div className="relative flex gap-x-8">
      <div className="flex flex-col w-[40%] font-fira">
        {column1.map((item, index) => (
          <label key={index} className="flex items-center mb-2 text-fira-light-table">
            <input
              type="checkbox"
              className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none"
            />
            {item.nombre}
          </label>
        ))}
      </div>
      <div className="flex flex-col w-[60%] font-fira">
        {column2.map((item, index) => (
          <label key={index} className="flex items-center mb-2 text-fira-light-table">
            <input
              type="checkbox"
              className="mr-2 appearance-none border border-gray-400 rounded-sm w-4 h-4 checked:bg-teal-500 checked:border-transparent focus:outline-none"
            />
            {item.nombre}
          </label>
        ))} {/* Contenedor para el botón */}
     <div className="flex justify-end mt-4"> {/* Añadido para alinear el botón a la derecha */}
    <button
      onClick={() => console.log('Confirmar cambios')}
      className="bg-teal-500 text-white px-2 py-1 rounded-md text-sm"
    >
      Confirmar
    </button>
  </div>
    </div>
    </div>
    
    
    
    )
}
export default AtributosCaracteristicas;